import QuickviewButton from './quickview-button';

const selectors = {
  trigger: '[data-trigger-quickview]',
  dataSectionId: 'data-section-id',
};

const classes = {
  init: 'is-init',
};

let sections = {};

class Quickview {
  constructor(container) {
    this.container = container;
    this.id = this.container.dataset.sectionId;

    if (theme.settings.showQuickView) {
      this.init();
    }
  }

  init() {
    sections[this.id] = [];
    const buttons = this.container.querySelectorAll(selectors.trigger);

    if (buttons.length) {
      buttons.forEach((element) => {
        if (!element.classList.contains(classes.init)) {
          sections[this.id].push(new QuickviewButton(element, this.id));
          element.classList.add(classes.init);
        }
      });
    }
  }
}

const quickviewSection = {
  onLoad() {
    this.section = new Quickview(this.container);
  },
};

export {quickviewSection, Quickview};
