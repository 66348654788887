import {register} from '../vendor/theme-scripts/theme-sections';
import {makeGridSwatches} from '../features/swatch';
import {Quickview} from '../features/quickview';

const sections = {};

const selectors = {
  section: '[data-section-type="related-products"]',
  product: '[data-product-block]',
  productImage: '[data-product-image]',
  sectionId: 'data-section-id',
  productId: 'data-product-id',
  limit: 'data-limit',
};

class RelatedProducts {
  constructor(section) {
    this.container = section.container;

    this.init();
  }

  init() {
    const relatedSection = this.container;
    const sectionId = relatedSection.getAttribute(selectors.sectionId);
    const productId = relatedSection.getAttribute(selectors.productId);
    const limit = relatedSection.getAttribute(selectors.limit);
    const requestUrl = `${theme.routes.product_recommendations_url}?section_id=${sectionId}&limit=${limit}&product_id=${productId}`;

    fetch(requestUrl)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const createdElement = document.createElement('div');
        createdElement.innerHTML = data;
        const inner = createdElement.querySelector(selectors.section);

        if (inner.querySelector(selectors.product)) {
          relatedSection.innerHTML = inner.innerHTML;

          makeGridSwatches(relatedSection);
          new Quickview(relatedSection);
        }
      });
  }
}

const RelatedSection = {
  onLoad() {
    sections[this.id] = new RelatedProducts(this);
  },
};

register('related-products', RelatedSection);
