import {register} from '../vendor/theme-scripts/theme-sections';
import {readHeights} from '../globals/height';
import outerWidth from '../features/outer-width-margins';
import navigation from '../features/navigation';
import {collapsible} from '../features/collapsible';

const selectors = {
  bodyWrap: '[data-body-wrap]',
  mainContent: '.main-content',
  navMain: '[data-nav-main]',
  navItem: '.menu > .menu-item',
  headerRow: '.container > .row',
  headerIcons: '[data-header-icons]',
  dataHeaderStyle: '[data-header-style="transparent"]',
  logoImage: '[data-logo-image]',
  logoImageWidth: 'data-width',
  logoText: '[data-logo-text]',
  hamburgerIcon: '[data-hamburger-icon]',
  hamburgerMenuScrollable: '[data-hamburger-scrollable]',
  cartClose: '[data-cart-close]',
  dataTransparent: 'data-transparent',
};

const classes = {
  headerFull: 'header--full',
  headerScrolled: 'header--has-scrolled',
  headerSticky: 'header--sticky',
  headerStandard: 'header--is-standard',
  headerHamburger: 'header--is-hamburger',
  headerTransparent: 'header--transparent',
  headerLogoCenterCenter: 'header--logo_center_links_center',
  headerLogoLeftCenter: 'header--logo_left_links_center',
  headerLogoCenterLeft: 'header--logo_center_links_left',
  headerLogoLeftLeft: 'header--logo_left_links_left',
  headerHiding: 'header--is-hiding',
  hasTransparentHeader: 'has-transparent-header',
  navVisible: 'nav--is-visible',
  cartVisible: 'cart--is-visible',
  open: 'open',
};

let sections = {};

class Header {
  constructor(el) {
    this.header = el;
    this.headerContainer = this.header.parentNode;
    this.html = document.documentElement;
    this.body = document.body;
    this.bodyWrap = document.querySelector(selectors.bodyWrap);
    this.headerStateEvent = (event) => this.stickyHeaderState(event);
    this.checkMobileNavEvent = () => this.checkMobileNav();
    this.checkNavOverlapEvent = () => this.checkNavigationOverlapping();
    this.checkTransparentHeaderEvent = (e) => this.checkTransparentHeader(e);
    this.init();
  }

  init() {
    this.resetHeader();
    this.checkTransparentHeader();
    this.checkNavigationOverlapping();

    window.addEventListener('load', this.checkNavOverlapEvent);
    document.addEventListener('theme:resize:width', this.checkNavOverlapEvent);
    document.addEventListener('theme:resize:width', this.checkMobileNavEvent);
    document.addEventListener('theme:header:update', this.checkTransparentHeaderEvent);

    if (this.header.classList.contains(classes.headerSticky)) {
      this.stickyHeaderState();
      document.addEventListener('theme:scroll', this.headerStateEvent);
    }
  }

  stickyHeaderState(event) {
    const scrolled = window.scrollY;
    const scrollUp = event && event.detail && event.detail.up;
    const {headerInitialHeight} = readHeights();
    this.headerHeight = this.headerHeight || headerInitialHeight;

    if (scrollUp && scrolled < this.headerHeight * 2) {
      this.header.classList.add(classes.headerHiding);
    } else {
      this.header.classList.remove(classes.headerHiding);
    }

    if (scrolled > this.headerHeight) {
      this.header.classList.add(classes.headerScrolled);
      this.header.classList.remove(classes.headerTransparent);
    } else {
      this.header.classList.remove(classes.headerScrolled);
      if (this.isHeaderTransparent()) {
        this.header.classList.add(classes.headerTransparent);
      }
    }
  }

  checkNavigationOverlapping() {
    const isDesktop = window.innerWidth >= theme.sizes.large;

    this.header.classList.remove(classes.headerHamburger);
    this.header.classList.add(classes.headerStandard);

    if (this.getNavigationOverlapping() || !isDesktop) {
      this.header.classList.remove(classes.headerStandard);
      this.header.classList.add(classes.headerHamburger);
    }
  }

  checkMobileNav() {
    const isDesktop = window.innerWidth >= theme.sizes.large;
    const isHamburgerNavOpen = this.body.classList.contains(classes.navVisible);

    if (isHamburgerNavOpen && isDesktop) {
      this.resetHeader();
    }
  }

  checkTransparentHeader() {
    if (this.isHeaderTransparent()) {
      this.body.classList.add(classes.hasTransparentHeader);
      this.header.classList.add(classes.headerTransparent);
    } else {
      this.body.classList.remove(classes.hasTransparentHeader);
      this.header.classList.remove(classes.headerTransparent);
    }
  }

  getNavigationOverlapping() {
    const headerRowWidth = this.header.querySelector(selectors.headerRow).offsetWidth;
    const navMenuWidth = this.getMenuItemsWidth();
    const headerClasses = this.header.classList;
    const isNavCentered = headerClasses.contains(classes.headerLogoCenterCenter) || headerClasses.contains(classes.headerLogoLeftCenter);
    const isNavLeftLogoCentered = headerClasses.contains(classes.headerLogoCenterLeft) || headerClasses.contains(classes.headerLogoLeftLeft);
    const additionalSpace = 40; // Additional spacing from margins
    let isNavigationOverlapping = false;
    let headerIconsWidth = this.header.querySelector(selectors.headerIcons).offsetWidth;
    let logoWidth = this.getLogoWidth();

    if (isNavCentered) {
      logoWidth = logoWidth < headerIconsWidth ? headerIconsWidth : logoWidth;
      logoWidth *= 2;
      headerIconsWidth = 0;
    }

    isNavigationOverlapping = parseInt(headerRowWidth) < parseInt(navMenuWidth + logoWidth + headerIconsWidth + additionalSpace);

    if (isNavLeftLogoCentered && logoWidth) {
      isNavigationOverlapping = parseInt((headerRowWidth - logoWidth) / 2) < parseInt(navMenuWidth);
    }

    return isNavigationOverlapping;
  }

  getLogoWidth() {
    const logoImage = this.header.querySelector(selectors.logoImage);
    const logoText = this.header.querySelector(selectors.logoText);
    let logoWidth = 0;

    if (logoImage !== null) {
      logoWidth = parseInt(logoImage.getAttribute(selectors.logoImageWidth));
    }

    if (logoText !== null) {
      logoWidth += logoText.offsetWidth;
    }

    return logoWidth;
  }

  getMenuItemsWidth() {
    let itemsWidth = 0;
    const navStandard = this.header.querySelector(selectors.navMain);
    const menuItems = navStandard.querySelectorAll(selectors.navItem);
    menuItems.forEach((menuItem) => {
      itemsWidth += outerWidth(menuItem);
    });

    return itemsWidth;
  }

  isHeaderTransparent() {
    const firstSection = document.querySelector(selectors.mainContent).firstElementChild;

    if (!firstSection) return false;

    const firstSectionClass = firstSection?.classList.contains(classes.headerFull);
    const firstSectionStyle = firstSection?.querySelector(selectors.dataHeaderStyle) !== null;
    const transparentHeader = this.header.getAttribute(selectors.dataTransparent) === 'true';
    const headerFull = firstSectionClass || firstSectionStyle;
    const headerTransparent = transparentHeader && headerFull;

    return headerTransparent;
  }

  resetHeader() {
    const hamburger = this.header.querySelector(selectors.hamburgerIcon);

    this.body.classList.remove(classes.navVisible);
    hamburger.classList.remove(classes.open);

    if (this.body.classList.contains(classes.cartVisible)) {
      document.querySelector(selectors.cartClose).click();
    }

    // Unlock page scroll
    document.dispatchEvent(new CustomEvent('theme:scroll:unlock', {bubbles: true}));
  }

  unload() {
    document.removeEventListener('theme:scroll', this.headerStateEvent);
    document.removeEventListener('theme:resize:width', this.checkNavOverlapEvent);
    document.removeEventListener('theme:resize:width', this.checkMobileNavEvent);
    document.removeEventListener('theme:header:update', this.checkTransparentHeaderEvent);
  }

  onselect() {
    this.resetHeader();
    this.init();
  }
}

const header = {
  onLoad() {
    sections = new Header(this.container);
  },
  onUnload() {
    if (typeof sections.unload === 'function') {
      sections.unload();
    }
  },
  onSelect() {
    if (typeof sections.onselect === 'function') {
      sections.onselect();
    }
  },
};

export {Header as default};

register('header', [header, navigation, collapsible]);
