/* eslint-disable consistent-return */
import AccordionElements from '../globals/accordions';

const selectors = {
  faqTrigger: '[data-faq-trigger]',
  accordionTrigger: '[data-accordion-trigger]',
};

const attributes = {
  faqTrigger: 'data-faq-trigger',
  index: 'data-index',
};

const classes = {
  isActive: 'is-active',
};

class FaqList extends AccordionElements {
  constructor() {
    super();
  }

  connectedCallback() {
    this.triggers = this.querySelectorAll(selectors.faqTrigger);

    this.triggers.forEach((button) => {
      button.addEventListener('click', (event) => {
        if (button.classList.contains(classes.isActive)) return;

        const activeButton = this.querySelector(`${selectors.faqTrigger}.${classes.isActive}`);
        const accordionButton = this.querySelector(`${selectors.accordionTrigger}[${attributes.index}="${button.dataset.index}"]`);

        activeButton?.classList.remove(classes.isActive);
        accordionButton?.dispatchEvent(new Event('click'));

        button.classList.add(classes.isActive);
      });
    });
  }
}

if (!customElements.get('faq-list')) {
  customElements.define('faq-list', FaqList);
}
