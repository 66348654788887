window.isYoutubeAPILoaded = false;
function loadYoutubeAPI() {
  if (!window.isYoutubeAPILoaded) {
    // Load Youtube API script
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}
function onYouTubeIframeAPIReady() {
  window.isYoutubeAPILoaded = true;
  document.body.dispatchEvent(new CustomEvent('theme:youtube:api-ready'));
}

window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
window.loadYoutubeAPI = loadYoutubeAPI;
