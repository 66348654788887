import Flickity from 'flickity';

import debounce from '../util/debounce';
import {register} from '../vendor/theme-scripts/theme-sections';

const sections = {};

const selectors = {
  logoListSlider: '[data-logo-list-slider]',
  logoListSlide: '[data-logo-list-slide]',
  logoListSlideData: 'data-logo-list-slide',
  logoListSlideIndex: 'data-slide-index',
};

const classes = {
  flickityEnabled: 'flickity-enabled',
};

class LogoList {
  constructor(section) {
    this.container = section.container;
    this.slideshow = this.container.querySelector(selectors.logoListSlider);
    this.sliderInitEvent = () => this.sliderInit();

    this.init();
  }

  init() {
    this.sliderInit();

    document.addEventListener('theme:resize', this.sliderInitEvent);
  }

  sliderInit() {
    const slidesCount = this.slideshow.querySelectorAll(selectors.logoListSlide).length;
    const slideWidth = 220;
    const windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
    const slidesWidth = slidesCount * slideWidth;
    const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

    if (windowWidth < slidesWidth) {
      if (!sliderInitialized) {
        this.flkty = new Flickity(this.slideshow, {
          autoPlay: 4000,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
        });
      }
    } else if (sliderInitialized) {
      this.flkty.destroy();
    }
  }

  onUnload() {
    const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

    if (sliderInitialized) {
      this.flkty.destroy();
    }

    document.removeEventListener('theme:resize', this.sliderInitEvent);
  }

  onBlockSelect(evt) {
    const slide = this.slideshow.querySelector(`[${selectors.logoListSlideData}="${evt.detail.blockId}"]`);
    const slideIndex = parseInt(slide.getAttribute(selectors.logoListSlideIndex));
    const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

    if (sliderInitialized) {
      this.flkty.select(slideIndex);
      this.flkty.pausePlayer();
    }
  }

  onBlockDeselect() {
    const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

    if (sliderInitialized) {
      this.flkty.unpausePlayer();
    }
  }
}

const LogoListSection = {
  onLoad() {
    sections[this.id] = new LogoList(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
  onBlockSelect(e) {
    sections[this.id].onBlockSelect(e);
  },
  onBlockDeselect() {
    sections[this.id].onBlockDeselect();
  },
};

register('logo-list', LogoListSection);
