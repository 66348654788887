import {subscribe} from '../util/pubsub';

const events = {
  cartUpdate: 'cart-update',
  variantChange: 'variant-change',
  cartError: 'cart-error',
};

class RecipientForm extends HTMLElement {
  cartUpdateUnsubscriber = undefined;
  variantChangeUnsubscriber = undefined;
  cartErrorUnsubscriber = undefined;
  constructor() {
    super();
    this.checkboxInput = this.querySelector(`#Recipient-Checkbox-${this.dataset.sectionIdForm}`);
    this.checkboxInput.disabled = false;
    this.hiddenControlField = this.querySelector(`#Recipient-Control-${this.dataset.sectionIdForm}`);
    this.hiddenControlField.disabled = true;
    this.emailInput = this.querySelector(`#Recipient-email-${this.dataset.sectionIdForm}`);
    this.nameInput = this.querySelector(`#Recipient-name-${this.dataset.sectionIdForm}`);
    this.messageInput = this.querySelector(`#Recipient-message-${this.dataset.sectionIdForm}`);
    this.sendonInput = this.querySelector(`#Recipient-send_on-${this.dataset.sectionIdForm}`);
    this.offsetProperty = this.querySelector(`#Recipient-Offset-${this.dataset.sectionIdForm}`);
    if (this.offsetProperty) this.offsetProperty.value = new Date().getTimezoneOffset();

    this.errorMessageWrapper = this.querySelector('.product-form__recipient-error-message-wrapper');
    this.errorMessageList = this.errorMessageWrapper?.querySelector('ul');
    this.errorMessage = this.errorMessageWrapper?.querySelector('span.error-message');
    this.defaultErrorHeader = this.errorMessage?.textContent;
    this.currentProductVariantId = this.dataset.productVariantId;
    this.addEventListener('change', this.onChange.bind(this));
    this.onChange();
  }

  connectedCallback() {
    this.cartUpdateUnsubscriber = subscribe(events.cartUpdate, (event) => {
      if (event.source === 'product-form' && event.productVariantId && event.productVariantId.toString() === this.currentProductVariantId) {
        this.resetRecipientForm();
      }
    });

    this.variantChangeUnsubscriber = subscribe(events.variantChange, (event) => {
      if (event.data.sectionId === this.dataset.sectionIdForm) {
        this.currentProductVariantId = event.data.variant.id.toString();
      }
    });

    this.cartUpdateUnsubscriber = subscribe(events.cartError, (event) => {
      if (event.source === 'product-form' && event.productVariantId && event.productVariantId.toString() === this.currentProductVariantId) {
        this.displayErrorMessage(event.message, event.errors);
      }
    });
  }

  disconnectedCallback() {
    if (this.cartUpdateUnsubscriber) {
      this.cartUpdateUnsubscriber();
    }

    if (this.variantChangeUnsubscriber) {
      this.variantChangeUnsubscriber();
    }

    if (this.cartErrorUnsubscriber) {
      this.cartErrorUnsubscriber();
    }
  }

  onChange() {
    if (!this.checkboxInput.checked) {
      this.clearInputFields();
      this.disableInputFields();
      this.clearErrorMessage();
    } else {
      this.enableInputFields();
    }
  }

  inputFields() {
    return [this.emailInput, this.nameInput, this.messageInput, this.sendonInput];
  }

  disableableFields() {
    return [...this.inputFields(), this.offsetProperty];
  }

  clearInputFields() {
    this.inputFields().forEach((field) => (field.value = ''));
  }

  enableInputFields() {
    this.disableableFields().forEach((field) => (field.disabled = false));
  }

  disableInputFields() {
    this.disableableFields().forEach((field) => (field.disabled = true));
  }

  displayErrorMessage(title, body) {
    this.clearErrorMessage();
    this.errorMessageWrapper.classList.add('is-visible');
    if (typeof body === 'object') {
      this.errorMessage.innerText = this.defaultErrorHeader;
      return Object.entries(body).forEach(([key, value]) => {
        const errorMessageId = `RecipientForm-${key}-error-${this.dataset.sectionIdForm}`;
        const fieldSelector = `#Recipient-${key}-${this.dataset.sectionIdForm}`;
        const message = `${value.join(', ')}`;
        const errorMessageElement = this.querySelector(`#${errorMessageId}`);
        const errorTextElement = errorMessageElement?.querySelector('.error-message');
        if (!errorTextElement) return;

        if (this.errorMessageList) {
          this.errorMessageList.appendChild(this.createErrorListItem(fieldSelector, message));
        }

        errorTextElement.innerText = `${message}.`;
        errorMessageElement.classList.remove('hidden');

        const inputElement = this[`${key}Input`];
        if (!inputElement) return;

        inputElement.setAttribute('aria-invalid', true);
        inputElement.setAttribute('aria-describedby', errorMessageId);
      });
    }

    this.errorMessage.innerText = body;
  }

  createErrorListItem(target, message) {
    const li = document.createElement('li');
    const a = document.createElement('a');
    a.setAttribute('href', target);
    a.innerText = message;
    li.appendChild(a);
    li.className = 'error-message';
    return li;
  }

  clearErrorMessage() {
    this.errorMessageWrapper.classList.remove('is-visible');

    if (this.errorMessageList) this.errorMessageList.innerHTML = '';

    this.querySelectorAll('.recipient-fields .form__message').forEach((field) => {
      field.classList.add('hidden');
      const textField = field.querySelector('.error-message');
      if (textField) textField.innerText = '';
    });

    [this.emailInput, this.messageInput, this.nameInput].forEach((inputElement) => {
      inputElement.setAttribute('aria-invalid', false);
      inputElement.removeAttribute('aria-describedby');
    });
  }

  resetRecipientForm() {
    if (this.checkboxInput.checked) {
      this.checkboxInput.checked = false;
      this.clearInputFields();
      this.clearErrorMessage();
    }
  }
}

export {RecipientForm};
