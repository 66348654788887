import throttle from '../util/throttle';

const selectors = {
  itemsParent: '[data-custom-scrollbar-items]',
  scrollbar: '[data-custom-scrollbar]',
  scrollbarTrack: '[data-custom-scrollbar-track]',
};
class CustomScrollbar {
  constructor(container) {
    this.itemsParent = container.querySelector(selectors.itemsParent);
    this.scrollbar = container.querySelector(selectors.scrollbar);
    this.scrollbarTrack = container.querySelector(selectors.scrollbarTrack);
    this.trackWidth = 0;
    this.calcPositionEvent = throttle(() => this.calculatePosition(), 50);
    this.calcTrackEvent = () => this.calculateTrackWidth();

    if (this.scrollbar && this.itemsParent) {
      this.events();
      this.calculateTrackWidth();
    }
  }

  events() {
    this.itemsParent.addEventListener('scroll', this.calcPositionEvent);
    document.addEventListener('theme:resize:width', this.calcTrackEvent);
  }

  calculateTrackWidth() {
    this.trackWidth = 100 / this.itemsParent.children.length;
    this.trackWidth = this.trackWidth < 5 ? 5 : this.trackWidth; // Min track width: 5%
    this.scrollbar.style.setProperty('--track-width', `${this.trackWidth}%`);
  }

  calculatePosition() {
    /* Scrollbar width must be re-calculated by subtracting the track width (in percentage)
     ** E.g. if the track width is 15% of the scroll bar then we must use a reduced by 15% scrollbar width for the calculations which is 85% of the total scrollbar width
     ** This is needed in order to prevent the track moving out of the scrollbar container
     */
    const reducedScrollbarWidth = this.scrollbar.clientWidth * ((100 - this.trackWidth) / 100);
    let position = this.itemsParent.scrollLeft / (this.itemsParent.scrollWidth - this.itemsParent.clientWidth);

    position *= reducedScrollbarWidth;

    this.scrollbar.style.setProperty('--position', `${Math.round(position)}px`);
  }

  destroy() {
    this.itemsParent.removeEventListener('scroll', this.calcPositionEvent);
    document.removeEventListener('theme:resize:width', this.calcTrackEvent);
  }
}

export default CustomScrollbar;
