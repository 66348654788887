import {register} from '../vendor/theme-scripts/theme-sections';
import {popoutSection} from '../features/popout';
import getSiblings from '../util/siblings';
import debounce from '../util/debounce';
import {swatchGridSection, makeGridSwatches, swatchSection, Swatch} from '../features/swatch';
import {quickviewSection, Quickview} from '../features/quickview';
import {ajaxify, Ajaxify} from '../features/infinity-scroll';
import {RangeSlider} from '../features/range-slider';

const sections = {};

const selectors = {
  accordionElements: 'accordion-elements',
  ariaExpanded: 'aria-expanded',
  ariaHidden: 'aria-hidden',
  collectionBlockImage: '[data-product-image]',
  collectionGridWrapper: '[data-collection-grid-wrapper]',
  collectionProducts: '[data-collection-products]',
  collectionSort: '[data-collection-sort]',
  collectionWrapper: '[data-collection-wrapper]',
  dataCollection: 'data-collection',
  dataCount: 'data-count',
  dataFilterMode: 'data-filter-mode',
  dataSort: 'data-sort',
  dataTag: 'data-tag',
  dataTags: 'data-tags',
  filterContainer: '[data-filter-container]',
  filterCount: '[data-filter-count]',
  filterTag: '[data-filter-tag]',
  filterTagButton: '[data-filter-tag-button]',
  filterTitle: '[data-accordion-trigger]',
  filters: '[data-filters]',
  filtersAvailable: 'data-filters-available',
  filtersResets: '[data-filters-reset]',
  filtersResetButton: '[data-filters-reset-button]',
  filtersEnable: 'data-filters-enable',
  filtersForm: '[data-filters-form]',
  filtersResetButtons: '[data-filter-reset-button]',
  inputs: 'input, select, label, textarea',
  pagination: '[data-pagination]',
  priceMin: '[data-field-price-min]',
  priceMax: '[data-field-price-max]',
  priceMinValue: 'data-field-price-min',
  priceMaxValue: 'data-field-price-max',
  rangeMin: '[data-se-min-value]',
  rangeMax: '[data-se-max-value]',
  rangeMinValue: 'data-se-min-value',
  rangeMaxValue: 'data-se-max-value',
  swatch: 'data-swatch',
};

const classes = {
  filtersTop: 'collection__filters--top',
  filtersHasTagsSelected: 'collection__filters--has-tags-selected',
  isActive: 'is-active',
  isExpanded: 'is-expanded',
  isLoading: 'is-loading',
};

class Collection {
  constructor(section) {
    this.container = section.container;
    this.collectionGridWrapper = this.container.querySelector(selectors.collectionGridWrapper);
    this.collectionSort = this.container.querySelector(selectors.collectionSort);
    this.collectionProducts = this.container.querySelector(selectors.collectionProducts);
    this.collectionWrapper = this.container.querySelector(selectors.collectionWrapper);
    this.pagination = this.container.querySelector(selectors.pagination);
    this.filterMode = this.container.getAttribute(selectors.dataFilterMode);
    this.accordionElements = this.container.querySelector(selectors.accordionElements);
    this.filters = this.container.querySelector(selectors.filters);
    this.filtersEnable = this.container.getAttribute(selectors.filtersEnable) === 'true';
    this.filtersForm = this.container.querySelector(selectors.filtersForm);
    this.filtersResets = this.container.querySelector(selectors.filtersResets);
    this.filtersInputs = [];
    this.sort = null;
    this.tags = null;
    this.collection = null;
    this.reset = null;
    this.resizeFiltersEvent = () => this.checkFiltersPosition();
    this.documentClick = (e) => this.closeOnOutsideClick(e);

    this.init();
  }

  init() {
    if (this.collectionSort) {
      this.initSort();
    }

    if (this.collectionProducts) {
      this.collection = this.collectionProducts.getAttribute(selectors.dataCollection);
    }

    if (this.filtersEnable) {
      if (this.filterMode === 'tag' || this.filterMode === 'group') {
        this.tags = JSON.parse(this.collectionProducts.getAttribute(selectors.dataTags));
        this.initFilters();
      }

      if (this.filterMode === 'default') {
        this.initFacetedFilters();
      }
    }

    this.initFilterToggleButtons();
    this.checkFiltersPosition();
    document.addEventListener('theme:resize', this.resizeFiltersEvent);
  }

  initSort() {
    this.collectionSort.addEventListener('change', (evt) => {
      const url = new window.URL(window.location.href);
      const value = evt.currentTarget.value;
      const params = url.searchParams;
      params.set('sort_by', value);
      url.search = params.toString();
      this.collectionProducts.setAttribute(selectors.dataSort, value);
      this.requestFilteredProducts(url.toString());
    });
  }

  initFilterToggleButtons() {
    if (!this.filters) {
      return;
    }

    // Close horizontal filters on click outside their container
    document.addEventListener('click', this.documentClick);
  }

  initFacetedFilters() {
    if (!this.filters.hasAttribute(selectors.filtersAvailable)) {
      return;
    }

    new RangeSlider(this.filtersForm);
    this.filtersInputs = this.filtersForm.querySelectorAll(selectors.inputs);

    if (this.filtersInputs.length) {
      this.filtersInputs.forEach((el) => {
        el.addEventListener(
          'input',
          debounce(() => {
            if (this.filtersForm && typeof this.filtersForm.submit === 'function') {
              this.submitForm();
            }
          }, 500)
        );
      });
    }

    this.filtersForm.addEventListener('theme:filter:range-update', () => this.updateRange());
    this.initResets();
  }

  submitForm() {
    const formData = new FormData(this.filtersForm);
    const search = new URLSearchParams(formData);
    this.requestFilteredProducts(`${this.collection}${search.toString()}`);
  }

  initFilters() {
    const filterButtons = this.filters.querySelectorAll(selectors.filterTagButton);

    // Select filter
    filterButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.toggleFilter(button);
        e.preventDefault();
      });
    });

    this.initResets();
  }

  initResets() {
    const resets = this.container.querySelectorAll(selectors.filtersResetButtons);
    this.resetAlt = this.collectionProducts.querySelector(selectors.filtersResetButton);

    // Bind reset
    if (resets.length) {
      resets.forEach((button) => {
        button.addEventListener('click', (e) => this.bindResetButton(e));
      });
    }
    if (this.resetAlt) {
      this.resetAlt.addEventListener('click', (e) => this.bindResetButton(e));
    }
  }

  toggleFilter(button) {
    const filterTag = button.parentNode;
    const selectedTag = button.getAttribute(selectors.dataTag);
    const isTagActive = filterTag.classList.contains(classes.isActive);
    const title = filterTag.parentNode.parentNode.previousElementSibling;
    this.sort = this.collectionProducts.getAttribute(selectors.dataSort);
    let sortParam = '';

    if (isTagActive) {
      const tagIndex = this.tags.indexOf(selectedTag);

      filterTag.classList.remove(classes.isActive);

      if (tagIndex > -1) {
        this.tags.splice(tagIndex, 1);
      }
    } else {
      filterTag.classList.add(classes.isActive);
      this.tags.push(selectedTag);
    }

    this.collectionProducts.setAttribute(selectors.dataTags, this.tags);

    const groupFiltersCount = filterTag.parentNode.querySelectorAll(`.${classes.isActive}`).length;

    title.querySelector(selectors.filterCount).setAttribute(selectors.dataCount, groupFiltersCount);

    if (this.isTopPosition() || isTagActive) {
      title.dispatchEvent(new Event('click'));
    }

    if (this.sort) {
      sortParam = `?sort_by=${this.sort}`;
    }

    const requestedURL = `${this.collection}/${this.tags.join('+')}${sortParam}`;

    this.requestFilteredProducts(requestedURL);
  }

  updateRange() {
    if (this.filtersForm && typeof this.filtersForm.submit === 'function') {
      const rangeMin = this.filtersForm.querySelector(selectors.rangeMin);
      const rangeMax = this.filtersForm.querySelector(selectors.rangeMax);
      const priceMin = this.filtersForm.querySelector(selectors.priceMin);
      const priceMax = this.filtersForm.querySelector(selectors.priceMax);
      const checkElements = rangeMin && rangeMax && priceMin && priceMax;

      if (checkElements && rangeMin.hasAttribute(selectors.rangeMinValue) && rangeMax.hasAttribute(selectors.rangeMaxValue)) {
        const priceMinValue = parseInt(priceMin.placeholder);
        const priceMaxValue = parseInt(priceMax.placeholder);
        const rangeMinValue = parseInt(rangeMin.getAttribute(selectors.rangeMinValue));
        const rangeMaxValue = parseInt(rangeMax.getAttribute(selectors.rangeMaxValue));

        if (priceMinValue !== rangeMinValue || priceMaxValue !== rangeMaxValue) {
          priceMin.value = rangeMinValue;
          priceMax.value = rangeMaxValue;

          this.submitForm();
        }
      }
    }
  }

  closeOnOutsideClick(e) {
    const isFiltersContainer = this.filters.contains(e.target);
    const isFilterExpanded = this.filters.querySelector('details[open]');

    // Close filter
    if (!isFiltersContainer && isFilterExpanded && this.isTopPosition()) {
      const expandedFilter = this.filters.querySelector('details[open]');
      expandedFilter.querySelector(selectors.filterTitle).dispatchEvent(new Event('click'));
    }
  }

  requestFilteredProducts(url) {
    const collectionWrapperTop = parseInt(Math.ceil(this.collectionWrapper.offsetTop) - theme.dimensions.headerScrolled);

    this.collectionWrapper.classList.add(classes.isLoading);

    // Scroll back to top
    window.scrollTo({
      top: collectionWrapperTop,
      left: 0,
      behavior: 'smooth',
    });

    if (history.replaceState) {
      window.history.pushState({path: url}, '', url);
    }

    fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const createdElement = document.createElement('div');
        createdElement.innerHTML = data;
        const collectionProducts = createdElement.querySelector(selectors.collectionProducts).innerHTML;
        const pagination = createdElement.querySelector(selectors.pagination);
        const filters = createdElement.querySelector(selectors.filters);

        this.collectionProducts.innerHTML = collectionProducts;

        if (this.pagination) {
          this.pagination.innerHTML = pagination !== null ? pagination.innerHTML : '';
        }

        if (!this.pagination && pagination !== null) {
          this.collectionGridWrapper.appendChild(pagination);
          this.pagination = this.collectionGridWrapper.querySelector(selectors.pagination);
        }

        if (filters) {
          const filtersHTML = filters.innerHTML;

          this.filters.innerHTML = filtersHTML;
          this.filtersForm = this.filters.querySelector(selectors.filtersForm);

          if (this.filterMode === 'tag' || this.filterMode === 'group') {
            this.initFilters();

            // Show reset button if there are tags selected
            if (this.tags.length > 0) {
              this.filters.classList.add(classes.filtersHasTagsSelected);
            } else {
              this.filters.classList.remove(classes.filtersHasTagsSelected);
            }
          }

          if (this.filterMode === 'default') {
            this.initFacetedFilters();
          }

          this.checkFiltersPosition();
          this.initFilterToggleButtons();
          this.bindSwatchFilters();
          new Collapsible(this.container);
        }

        ajaxify.onUnload();
        new Ajaxify(this.container);
        makeGridSwatches(this.container);
        new Quickview(this.container);
      })
      .catch((e) => {
        this.collectionWrapper.classList.remove(classes.isLoading);
      })
      .finally(() => {
        // Stop loading animation
        setTimeout(() => {
          this.collectionWrapper.classList.remove(classes.isLoading);
        }, 450);
      });
  }

  checkFiltersPosition() {
    if (!this.filters) {
      return;
    }

    const filters = this.filters.querySelectorAll('details');
    // Run only if filters position is set to "Top" or window width is < 1279
    if (this.isTopPosition()) {
      this.accordionElements.setAttribute('single', true);

      filters.forEach((filter) => {
        const filterExpanded = filter.hasAttribute('open');

        // Check if dropdown is expanded and close it
        if (filterExpanded) {
          filter.removeAttribute('open');
          filter.querySelector(selectors.filterContainer).style.height = 0;
        }
      });
    } else {
      this.accordionElements.removeAttribute('single');
    }
  }

  isTopPosition() {
    return window.innerWidth < theme.sizes.widescreen || this.filters.classList.contains(classes.filtersTop);
  }

  bindSwatchFilters() {
    this.swatches = [];
    const els = this.container.querySelectorAll(`[${selectors.swatch}]`);
    els.forEach((el) => {
      this.swatches.push(new Swatch(el));
    });
  }

  bindResetButton(e) {
    e.preventDefault();

    if (this.filterMode === 'tag' || this.filterMode === 'group') {
      const button = e.currentTarget;
      if (button.getAttribute(selectors.dataTag)) {
        const selectedTag = button.dataset.tag;
        const tagIndex = this.tags.indexOf(selectedTag);

        if (tagIndex > -1) {
          this.tags.splice(tagIndex, 1);
        }
      } else {
        this.tags = [];
      }
      this.collectionProducts.setAttribute(selectors.dataTags, this.tags);
    }

    this.requestFilteredProducts(e.currentTarget.href);
  }

  onUnload() {
    document.removeEventListener('click', this.documentClick);
    document.removeEventListener('theme:resize', this.resizeFiltersEvent);
  }
}

const CollectionSection = {
  onLoad() {
    sections[this.id] = new Collection(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
};

register('collection', [CollectionSection, quickviewSection, ajaxify, swatchGridSection, swatchSection, popoutSection]);
register('search-template', [CollectionSection, quickviewSection, ajaxify, swatchGridSection, swatchSection, popoutSection]);
