import {formatMoney} from '@shopify/theme-currency';
import * as a11y from '../vendor/theme-scripts/theme-a11y';
import slideUp from '../util/slide-up';
import slideDown from '../util/slide-down';
import debounce from '../util/debounce';
import FetchError from '../util/fetch-error';
import {publish} from '../util/pubsub';

import QuantityCounter from './quantity-handle';

const events = {
  cartUpdate: 'cart-update',
  cartError: 'cart-error',
};

const settings = {
  dimensions: {
    maxSize: 100,
  },
  times: {
    timeoutAddProduct: 1000,
    closeDropdownAfter: 5000,
  },
  classes: {
    template: 'template-cart',
    hidden: 'is-hidden',
    cartVisible: 'cart--is-visible',
    open: 'is-open',
    focused: 'is-focused',
    visible: 'is-visible',
    loading: 'is-loading',
    disabled: 'is-disabled',
    success: 'product__form-submit--success',
    defaultSuccess: 'is-success',
    cartEmpty: 'cartToggle--empty',
    isAdded: 'is-added',
  },
  attributes: {
    expanded: 'aria-expanded',
    disabled: 'disabled',
    dataId: 'data-id',
    cartTotalPrice: 'data-cart-total-price',
    freeMessageLimit: 'data-limit',
    hideErrors: 'data-hide-errors',
  },
  elements: {
    apiContent: '[data-api-content]',
    html: 'html',
    button: 'button',
    buttonAddToCart: '[data-add-to-cart]',
    buttonAddToCartText: '[data-add-to-cart-text]',
    buttonHolder: '[data-foot-holder]',
    buttonUpdateCart: '[data-update-cart]',
    cart: '[data-cart]',
    cartScroll: '[data-cart-scroll]',
    cartContainer: '[data-cart-container]',
    cartTemplate: '[data-cart-template]',
    cartToggleElement: '[data-cart-toggle]',
    cartClose: '[data-cart-close]',
    cartItemRemove: '[data-item-remove]',
    cartItemsCount: '[data-cart-items-count]',
    cartTotal: '[data-cart-total]',
    cartErrors: '[data-cart-errors]',
    cartCloseError: '[data-cart-error-close]',
    cartOriginalTotal: '[data-cart-original-total]',
    cartOriginaTotalPrice: '[data-cart-original-total-price]',
    cartDiscountsHolder: '[data-cart-discounts-holder]',
    cartAcceptanceCheckbox: '[data-cart-acceptance-checkbox]',
    cartButtons: '[data-cart-buttons]',
    cartButtonsFieldset: '[data-cart-buttons-fieldset]',
    cartFormError: '[data-cart-error]',
    cartMessage: 'data-cart-message',
    cartMessageItem: '[data-cart-message]',
    cartProgress: '[data-cart-progress]',
    continueBtn: '[data-continue]',
    emptyMessage: '[data-empty-message]',
    errorMessage: '[data-error-message]',
    input: 'input',
    item: '[data-item]',
    itemsHolder: '[data-items-holder]',
    leftToSpend: '[data-left-to-spend]',
    popover: '[data-popover]',
    popoverTemplate: '[data-popover-template]',
    qty: '[data-quantity-field]',
    quickAddHolder: '[data-quick-add-holder]',
    cartDiscountTitle: '[data-cart-discount-title]',
    cartDiscountPrice: '[data-cart-discount-price]',
  },
  cartTotalDiscountsTemplate: '[data-cart-total-discount]',
};

class CartDrawer {
  constructor() {
    if (window.location.pathname === '/password') {
      return;
    }

    this.init();
  }

  init() {
    // DOM Elements
    this.html = document.querySelector(settings.elements.html);
    this.body = document.body;

    this.defineSelectors();
    this.accessibility = a11y;
    this.ajaxEnabled = theme.settings.enableAjaxCart;
    this.popoverTimer = '';
    this.scrollLockTimeout = 0;
    this.cartFocusTimeout = 0;
    this.form = null;
    this.cartItemsCount = document.querySelector(settings.elements.cartItemsCount);
    this.hideErrors = false;

    // Flags
    this.cartDrawerIsOpen = false;
    this.cartDiscounts = 0;
    this.cartLimitErrorIsHidden = true;

    // Cart events
    this.openCartDrawer = this.openCartDrawer.bind(this);
    this.closeCartDrawer = this.closeCartDrawer.bind(this);
    this.toggleCartDrawer = this.toggleCartDrawer.bind(this);
    this.cartKeyUpEvent = this.cartKeyUpEvent.bind(this);

    if (this.ajaxEnabled) {
      this.eventToggleCart();
    }

    this.initDefaultCartEvents();
    this.addProductEvent();
  }

  /**
   * Render cart and define all elements after cart drawer is open for a first time
   *
   * @return  {Void}
   */
  renderCart() {
    // Append cart template html to the cart drawer
    this.cartContainer.innerHTML = document.querySelector(settings.elements.cartTemplate).innerHTML;
    this.totalItems = this.items.length;

    this.defineSelectors();
    this.initDefaultCartEvents();

    this.getCart();
  }

  /**
   * Define cart selectors
   *
   * @return  {Void}
   */
  defineSelectors() {
    this.cartContainer = document.querySelector(settings.elements.cartContainer);
    this.cartTemplate = document.querySelector(settings.elements.cartTemplate);
    this.popover = document.querySelector(settings.elements.popover);
    this.popoverTemplate = document.querySelector(settings.elements.popoverTemplate).innerHTML;
    this.cart = document.querySelector(settings.elements.cart);
    this.cartScroll = document.querySelector(settings.elements.cartScroll);
    this.emptyMessage = document.querySelector(settings.elements.emptyMessage);
    this.buttonHolder = document.querySelector(settings.elements.buttonHolder);
    this.itemsHolder = document.querySelector(settings.elements.itemsHolder);
    this.items = document.querySelectorAll(settings.elements.item);
    this.cartToggle = document.querySelector(settings.elements.cartToggleElement);
    this.continueBtns = document.querySelectorAll(settings.elements.continueBtn);
    this.cartTotal = document.querySelector(settings.elements.cartTotal);
    this.cartOriginalTotal = document.querySelector(settings.elements.cartOriginalTotal);
    this.cartOriginaTotalPrice = document.querySelector(settings.elements.cartOriginaTotalPrice);
    this.cartDiscountHolder = document.querySelector(settings.elements.cartDiscountsHolder);
    this.cartTotalDiscountTemplate = document.querySelector(settings.cartTotalDiscountsTemplate).innerHTML;
    this.cartErrorHolder = document.querySelector(settings.elements.cartErrors);
    this.cartClose = document.querySelector(settings.elements.cartClose);
    this.cartCloseErrorMessage = document.querySelector(settings.elements.cartCloseError);
    this.cartAcceptanceCheckbox = document.querySelector(settings.elements.cartAcceptanceCheckbox);
    this.cartMessage = document.querySelector(`[${settings.elements.cartMessage}]`);
    this.leftToSpend = document.querySelectorAll(settings.elements.leftToSpend);
  }

  /**
   * Init default cart events
   *
   * @return  {Void}
   */
  initDefaultCartEvents() {
    // Cart Events
    if (this.ajaxEnabled) {
      this.cartEvents();
      this.customEventAddProduct();
    } else if (this.items.length) {
      this.noAjaxUpdate();
    }

    // Init quantity for fields
    this.initQuantity(this.ajaxEnabled);

    if (this.cartMessage) {
      this.cartFreeLimitShipping = Number(this.cartMessage.getAttribute(settings.attributes.freeMessageLimit)) * window.Shopify.currency.rate;
      this.subtotal = Number(this.cartMessage.getAttribute(settings.attributes.cartTotalPrice));
      this.cartBarProgress();
      this.updateProgress();
    }
  }

  /**
   * Init quantity field functionality
   *
   * @return  {Void}
   */

  initQuantity(ajax) {
    this.items = document.querySelectorAll(settings.elements.item);

    this.items.forEach((item) => {
      const initQuantity = new QuantityCounter(item, true);

      initQuantity.init();
      if (ajax) {
        this.customEventsHandle(item);
      }
    });
  }

  noAjaxUpdate() {
    const updateBtn = this.buttonHolder.querySelector(settings.elements.buttonUpdateCart);
    updateBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.items.forEach((item) => {
        const qty = item.querySelector(`input[${settings.attributes.dataId}]`);
        this.updateCart({
          id: qty.getAttribute(settings.attributes.dataId),
          quantity: qty.value,
        });
      });
    });
  }

  /**
   * Custom event who change the cart
   *
   * @return  {Void}
   */

  customEventsHandle(holder) {
    holder.addEventListener(
      'theme:cart:update',
      debounce((event) => {
        this.updateCart(
          {
            id: event.detail.id,
            quantity: event.detail.quantity,
          },
          holder,
          event.detail.valueIsEmpty
        );
      }, 500)
    );
  }

  /**
   *  Custom event for add product to the cart
   */
  customEventAddProduct() {
    document.addEventListener(
      'theme:cart:add',
      debounce((event) => {
        this.cartToggle.classList.add(settings.classes.isAdded);
        setTimeout(() => {
          this.cartToggle.classList.remove(settings.classes.isAdded);
        }, 800);
      }, 500)
    );
  }

  /**
   * Cart events
   *
   * @return  {Void}
   */

  cartEvents() {
    const cartItemRemove = document.querySelectorAll(settings.elements.cartItemRemove);

    if (cartItemRemove) {
      cartItemRemove.forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
          event.target.closest(settings.elements.item).classList.add(settings.classes.loading);

          this.updateCart({
            id: item.getAttribute(settings.attributes.dataId),
            quantity: 0,
          });
        });
      });
    }

    if (this.cartCloseErrorMessage) {
      this.cartCloseErrorMessage.addEventListener('click', (event) => {
        event.preventDefault();

        slideUp(this.cartErrorHolder, 400);
      });
    }

    // Continue Shopping Button
    if (this.continueBtns) {
      this.continueBtns.forEach((continueBtn) => {
        continueBtn.addEventListener('click', (e) => {
          const referrer = document.referrer;
          const origin = window.location.origin + '/';
          const isDesktop = window.innerWidth >= theme.sizes.small;

          e.preventDefault();

          if (isDesktop && !(window.location.href.indexOf('/cart') > -1)) {
            this.closeCartDrawer();
          } else if (referrer === origin) {
            window.location.href = theme.routes.root;
          } else {
            history.back(1);
          }
        });
      });
    }

    // Close Cart Button
    if (this.cartClose) {
      this.cartClose.addEventListener('click', this.closeCartDrawer);
    }

    // Esc key close cart dropdown and popover
    if (this.cartContainer) {
      this.cartContainer.addEventListener('keyup', this.cartKeyUpEvent);
    }

    // Terms and conditions checkbox listener
    if (this.cartAcceptanceCheckbox) {
      this.cart.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const isCartButtons = clickedElement.matches(settings.elements.cartButtons) || clickedElement.closest(settings.elements.cartButtons);
        if (isCartButtons) {
          this.termsAcceptance(event);
        }
      });
      this.cartAcceptanceCheckbox.addEventListener('change', (event) => this.termsAcceptance(event));

      if (this.cartAcceptanceCheckbox.checked === false) {
        this.cart.querySelector(settings.elements.cartButtonsFieldset).setAttribute(settings.attributes.disabled, true);
      }
    }
  }

  cartKeyUpEvent(e) {
    const key = e.which || e.keyCode;

    if (key === theme.keyboardKeys.ESCAPE && this.cartDrawerIsOpen) {
      this.closeCartDrawer();
      this.popoverHide();
    }
  }

  /**
   * Disable checkout if terms not accepted
   *
   * @return  {Void}
   */

  termsAcceptance(event) {
    const termsNotAccepted = this.cartAcceptanceCheckbox.checked === false;
    const cartFormError = this.cart.querySelector(settings.elements.cartFormError);
    const cartButtonsFieldset = this.cart.querySelector(settings.elements.cartButtonsFieldset);

    // Disable form submit if terms and conditions are not accepted
    if (termsNotAccepted) {
      event.preventDefault();
      cartButtonsFieldset.setAttribute(settings.attributes.disabled, true);
      slideDown(cartFormError);
    } else {
      cartButtonsFieldset.removeAttribute(settings.attributes.disabled);
      slideUp(cartFormError);
    }
  }

  /**
   * Cart Popover
   *
   * @return  {Void}
   */

  renderPopover(product, qty) {
    const stripHtmlRegex = /(<([^>]+)>)/gi;
    const productTitle = product.title.replace(stripHtmlRegex, '');
    let price = product.final_price === 0 ? window.theme.translations.free : formatMoney(product.final_price, theme.settings.moneyFormat);
    let prodImg = theme.assets.no_image;
    let unitPrice = '';
    const sellingPlanName = product.selling_plan_allocation ? product.selling_plan_allocation.selling_plan.name : null;
    let properties = '';

    // Unit price
    if (product.unit_price_measurement) {
      unitPrice = `${formatMoney(product.unit_price, theme.moneyWithoutCurrencyFormat)} / `;
      if (product.unit_price_measurement.reference_value != 1) {
        unitPrice += product.unit_price_measurement.reference_value;
      }
      unitPrice += product.unit_price_measurement.reference_unit;
    }

    // Product image
    if (product.featured_image != null) {
      prodImg = product.featured_image.url;
    }

    // Properties
    if (product.properties) {
      for (const property in product.properties) {
        if ({}.hasOwnProperty.call(product.properties, property)) {
          const propValue = product.properties[property];
          const propFirstChar = property.slice(0, 1);

          if (propValue && propFirstChar !== '_') {
            properties += '<p>' + property + ': ' + product.properties[property] + '</p>';
          }
        }
      }
    }

    let popoverReplaced = this.popoverTemplate;
    popoverReplaced = popoverReplaced.includes('||itemCount||') ? popoverReplaced.replaceAll('||itemCount||', qty) : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductTitle||') ? popoverReplaced.replaceAll('||itemProductTitle||', productTitle) : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductImage||') ? popoverReplaced.replaceAll('||itemProductImage||', prodImg) : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductVariation||')
      ? popoverReplaced.replaceAll('||itemProductVariation||', product.product_has_only_default_variant ? '' : product.variant_title)
      : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductSellingPlan||') ? popoverReplaced.replaceAll('||itemProductSellingPlan||', sellingPlanName ? sellingPlanName : '') : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductProperties||') ? popoverReplaced.replaceAll('||itemProductProperties||', properties) : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductPrice||') ? popoverReplaced.replaceAll('||itemProductPrice||', price) : popoverReplaced;
    popoverReplaced = popoverReplaced.includes('||itemProductUnitPrice||') ? popoverReplaced.replaceAll('||itemProductUnitPrice||', unitPrice) : popoverReplaced;

    return popoverReplaced;
  }

  popoverShow(product, quantity) {
    this.popover.innerHTML = this.renderPopover(product, quantity);
    this.popover.classList.add(settings.classes.visible);

    // clear popover timer, set at top of Cart object
    clearTimeout(this.popoverTimer);

    // set new instace of popoverTimer
    this.popoverTimer = setTimeout(() => {
      this.popoverHide();
    }, settings.times.closeDropdownAfter);
  }

  popoverHide() {
    this.popover.classList.remove(settings.classes.visible);
    setTimeout(() => {
      this.popover.innerHtml = '';
    }, 300);
  }

  /**
   * Cart event add product to cart
   *
   * @return  {Void}
   */

  addProductEvent() {
    document.addEventListener('click', (event) => {
      if (event.target.matches(settings.elements.buttonAddToCart)) {
        event.preventDefault();
        const button = event.target;

        if (button.hasAttribute(settings.attributes.disabled)) {
          return;
        }

        button.setAttribute(settings.attributes.disabled, true);
        this.form = button.closest('form');
        this.hideErrors = this.form?.getAttribute(settings.attributes.hideErrors) === 'true';
        const quantity = this.form.querySelector(settings.elements.qty).value;
        const formData = new FormData(this.form);

        if (this.form.querySelector('[type="file"]')) {
          return;
        }

        this.addToCart(formData, null, button, quantity);

        document.dispatchEvent(
          new CustomEvent('theme:cart:add', {
            bubbles: true,
            detail: {
              selector: button,
            },
          })
        );
      }
    });
  }

  /**
   * Get response from the cart
   *
   * @return  {Void}
   */

  getCart() {
    fetch(theme.routes.root + 'cart.js')
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((response) => {
        this.updateCounter(response.item_count);

        if (this.cart !== null) {
          this.newTotalItems = response.items.length;

          this.buildTotalPrice(response);
          this.freeShippingMessageHandle(response.total_price);
          this.subtotal = response.total_price;
        }

        return fetch(theme.routes.cart_url + '?section_id=api-cart-items');
      })
      .then((response) => response.text())
      .then((response) => {
        this.build(response);

        // Build cart again if the quantity of the changed product is 0 or cart discounts are changed
        if (this.cartMessage) {
          this.updateProgress();
        }
      })
      .catch((error) => console.log(error));
  }

  /**
   * Add item(s) to the cart and show the added item(s)
   *
   * @param   {String}  data
   * @param   {DOM Element}  quickAddHolder
   * @param   {DOM Element}  button
   *
   * @return  {Void}
   */

  addToCart(data, quickAddHolder = null, button = null, quantity = 1) {
    const variantId = data.get('id');

    fetch(theme.routes.root + 'cart/add.js', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/javascript',
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        let error = false;

        if (response.status) {
          publish(events.cartError, {source: 'product-form', productVariantId: variantId, errors: response.description, message: response.message});

          if (quickAddHolder !== null) {
            this.addToCartError(response, quickAddHolder.element, button);
          } else {
            this.addToCartError(response, null, button);
          }

          if (this.hideErrors && button !== null) {
            button.removeAttribute(settings.attributes.disabled);
          }

          error = true;
          this.hideErrors = false;

          return;
        }

        if (!error) {
          publish(events.cartUpdate, {source: 'product-form', productVariantId: variantId});
        }

        if (this.ajaxEnabled) {
          this.cart !== null ? this.getCart() : this.renderCart();

          if (button) {
            button.classList.remove(settings.classes.loading);
            button.classList.add(settings.classes.success);
          }

          setTimeout(() => {
            if (button !== null) {
              button.removeAttribute(settings.attributes.disabled);
              button.classList.remove(settings.classes.success);
            }

            const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (windowWidth >= theme.sizes.mobile) {
              this.popoverShow(response, quantity);
            }
          }, settings.times.timeoutAddProduct);
        } else {
          window.location.href = theme.routes.cart_url;
        }
      })
      .catch((error) => console.log(error));
  }

  /**
   * Update cart
   *
   * @param   {Object}  updateData
   *
   * @return  {Void}
   */

  updateCart(updateData = {}, holder = null, valueIsEmpty = false) {
    let newCount = null;
    let oldCount = null;
    let newItem = null;
    let settedQuantity = updateData.quantity;

    if (holder !== null) {
      holder.closest(settings.elements.item).classList.add(settings.classes.loading);
    }

    this.items.forEach((item) => {
      item.classList.add(settings.classes.disabled);
      item.querySelector(settings.elements.input).setAttribute(settings.attributes.disabled, true);
      item.querySelector(settings.elements.input).blur();
      item.querySelectorAll(settings.elements.button).forEach((button) => {
        button.setAttribute(settings.attributes.disabled, true);
      });
    });

    fetch(theme.routes.root + 'cart.js')
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((response) => {
        const matchKeys = (item) => item.key === updateData.id;
        const index = response.items.findIndex(matchKeys);
        oldCount = response.item_count;
        newItem = response.items[index].title;

        const data = {
          line: `${index + 1}`,
          quantity: settedQuantity,
        };

        return fetch(theme.routes.root + 'cart/change.js', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(data),
        });
      })
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((response) => {
        newCount = response.item_count;

        if (valueIsEmpty) {
          settedQuantity = 1;
        }

        if (this.ajaxEnabled) {
          if (settedQuantity !== 0) {
            this.cartLimitErrorIsHidden = newCount !== oldCount;

            this.toggleLimitError(newItem);
          } else {
            this.cartLimitErrorIsHidden = true;
            this.toggleLimitError();
          }

          this.updateCounter(newCount);

          // Change the cart total and hide message if missing discounts and the changed product is not deleted
          this.buildTotalPrice(response);
          this.freeShippingMessageHandle(response.total_price);
          this.cartDiscounts = response.total_discount;

          // Build cart again if the quantity of the changed product is 0 or cart discounts are changed
          if (this.cartMessage) {
            this.subtotal = response.total_price;
            this.updateProgress();
          }

          this.getCart();
        } else {
          const form = this.buttonHolder.closest('form');
          response.items.forEach((item) => {
            if (item.key === updateData.id) {
              form.querySelector(`[${settings.attributes.dataId}="${item.key}"]`).value = item.quantity;
            }
          });
          form.submit();
        }
      })
      .catch((error) => {
        console.log(error);

        this.cartLimitErrorIsHidden = false;
        this.toggleLimitError(error.json.message);
        this.resetItems();
      });
  }

  resetItems() {
    this.items.forEach((item) => {
      const input = item.querySelector(settings.elements.input);
      const qty = input.getAttribute('value');
      input.removeAttribute(settings.attributes.disabled);
      input.value = qty;

      item.classList.remove(settings.classes.disabled, settings.classes.loading);
      item.querySelectorAll(settings.elements.button).forEach((button) => {
        button.removeAttribute(settings.attributes.disabled);
      });
    });
  }

  /**
   * Show/hide limit error
   *
   * @param   {String}  itemTitle
   *
   * @return  {Void}
   */

  toggleLimitError(itemTitle = '') {
    this.cartErrorHolder.querySelector(settings.elements.errorMessage).innerText = itemTitle;

    if (this.cartLimitErrorIsHidden) {
      slideUp(this.cartErrorHolder, 400);
    } else {
      slideDown(this.cartErrorHolder, 400);
    }
  }

  /**
   * Handle errors
   *
   * @param   {Object}  response
   *
   * @return  {Object}
   */

  handleErrors(response) {
    if (!response.ok) {
      return response.json().then(function (json) {
        const e = new FetchError({
          status: response.statusText,
          headers: response.headers,
          json: json,
        });
        throw e;
      });
    }
    return response;
  }

  /**
   * Add to cart error handle
   *
   * @param   {Object}  data
   * @param   {DOM Element/Null} quickAddHolder
   * @param   {DOM Element/Null} button
   *
   * @return  {Void}
   */

  addToCartError(data, quickAddHolder, button) {
    if (!this.ajaxEnabled || this.hideErrors) {
      return;
    }
    let errorContainer = this.popover;

    if (button !== null) {
      const addToCartText = button.querySelector(settings.elements.buttonAddToCartText);
      addToCartText.textContent = theme.translations.form_submit_error;
      button.setAttribute(settings.attributes.disabled, settings.attributes.disabled);

      setTimeout(() => {
        button.removeAttribute(settings.attributes.disabled);
        addToCartText.textContent = theme.translations.form_submit; // swap it back
      }, 1000);
    }

    clearTimeout(this.popoverTimer);

    if (errorContainer) {
      errorContainer.innerHTML = `<div class="popover-error">${data.message}: ${data.description}</div>`;

      errorContainer.classList.add(settings.classes.visible);
    }

    if (quickAddHolder) {
      this.html.dispatchEvent(
        new CustomEvent('theme:cart:add-error', {
          bubbles: true,
          detail: {
            message: data.message,
            description: data.description,
            holder: quickAddHolder,
          },
        })
      );
    }

    this.popoverTimer = setTimeout(() => {
      errorContainer.classList.remove(settings.classes.visible);
    }, settings.times.closeDropdownAfter);
  }

  /**
   * Open cart dropdown and add class on body
   *
   * @return  {Void}
   */

  openCartDrawer() {
    this.popoverHide();

    if (this.cart === null) {
      this.renderCart();
    }

    document.dispatchEvent(
      new CustomEvent('theme:drawer:close', {
        bubbles: false,
      })
    );

    document.dispatchEvent(new CustomEvent('theme:scroll:lock', {bubbles: true, detail: this.cartScroll}));

    this.setCartClosePosition();
    this.body.classList.add(settings.classes.cartVisible);
    this.cart.classList.add(settings.classes.open);
    this.cartToggle.setAttribute(settings.attributes.expanded, true);
    this.accessibility.removeTrapFocus();
    this.cartDrawerIsOpen = true;

    // Reset cart focus timeout
    if (this.cartFocusTimeout) {
      clearTimeout(this.cartFocusTimeout);
    }

    // Focus first clickable element after drawer animation completes
    this.cartFocusTimeout = setTimeout(() => {
      this.accessibility.trapFocus(this.cart, {
        elementToFocus: this.cart.querySelector('a, button, input'),
      });
    }, 500);
  }

  /**
   * Close cart dropdown and remove class on body
   *
   * @return  {Void}
   */

  closeCartDrawer() {
    this.cartDrawerIsOpen = false;
    document.dispatchEvent(
      new CustomEvent('theme:cart-drawer:close', {
        bubbles: true,
      })
    );

    this.accessibility.removeTrapFocus();

    slideUp(this.cartErrorHolder, 400);

    if (this.body.classList.contains(settings.classes.focused)) {
      const button = document.querySelector(`${settings.elements.cartToggleElement}`);

      setTimeout(() => {
        button.focus();
      }, 200);
    }

    this.body.classList.remove(settings.classes.cartVisible);
    this.cart.classList.remove(settings.classes.open);
    this.cartToggle.setAttribute(settings.attributes.expanded, false);
    this.popoverHide();

    if (this.scrollLockTimeout) {
      clearTimeout(this.scrollLockTimeout);
    }

    // Unlock body scroll after animation completed to prevent content shifting
    this.scrollLockTimeout = setTimeout(() => {
      document.dispatchEvent(new CustomEvent('theme:scroll:unlock', {bubbles: true}));
    }, 500);
  }

  /**
   * Toggle cart dropdown
   *
   * @return  {Void}
   */

  toggleCartDrawer() {
    if (this.body.classList.contains(settings.classes.template)) {
      return;
    }

    this.cartDrawerIsOpen ? this.closeCartDrawer() : this.openCartDrawer();
  }

  /**
   * Event click to element to open cart dropdown
   *
   * @return  {Void}
   */

  eventToggleCart() {
    document.addEventListener('click', (event) => {
      const clickedElement = event.target;
      const isCartToggle = clickedElement.matches(settings.elements.cartToggleElement) || clickedElement.closest(settings.elements.cartToggleElement);
      const isPopover = clickedElement.matches(settings.elements.popover) || clickedElement.closest(settings.elements.popover);

      if (isCartToggle || isPopover) {
        this.toggleCartDrawer();
        event.preventDefault();
      }
    });
  }

  /**
   * Toggle classes on different containers and messages
   *
   * @return  {Void}
   */

  toggleClassesOnContainers() {
    this.emptyMessage.classList.toggle(settings.classes.hidden, this.hasItemsInCart());
    this.buttonHolder.classList.toggle(settings.classes.hidden, !this.hasItemsInCart());
    this.itemsHolder.classList.toggle(settings.classes.hidden, !this.hasItemsInCart());
  }

  /**
   * Build cart depends on results
   *
   * @param   {Object}  data
   *
   * @return  {Void}
   */

  build(data) {
    if (this.cart === null) {
      this.renderCart();
      return;
    }

    if (this.totalItems !== this.newTotalItems) {
      this.totalItems = this.newTotalItems;

      this.toggleClassesOnContainers();
    }

    const fresh = document.createElement('div');
    fresh.innerHTML = data;
    this.itemsHolder.innerHTML = fresh.querySelector(settings.elements.apiContent).innerHTML;

    this.cartEvents();
    this.initQuantity(this.ajaxEnabled);
  }

  /**
   * Update cart count
   *
   * @param   {Number}  countItems
   *
   * @return  {Void}
   */

  updateCounter(countItems) {
    if (countItems > 0) {
      this.cartToggle.classList.remove(settings.classes.cartEmpty);
    } else {
      this.cartToggle.classList.add(settings.classes.cartEmpty);
    }

    // Update cart icon counter
    if (this.cartItemsCount) {
      this.cartItemsCount.innerText = countItems < 10 ? countItems : '9+';
    }
  }

  /**
   * Check for items in the cart
   *
   * @return  {Void}
   */

  hasItemsInCart() {
    return this.totalItems > 0;
  }

  /**
   * Build total cart total price
   *
   * @param   {Object}  data
   *
   * @return  {Void}
   */

  buildTotalPrice(data) {
    if (this.cart !== null) {
      if (data.original_total_price > data.total_price && data.cart_level_discount_applications.length > 0) {
        this.cartOriginalTotal.classList.remove(settings.classes.hidden);
        this.cartOriginaTotalPrice.innerHTML = data.original_total_price === 0 ? window.theme.translations.free : formatMoney(data.original_total_price, theme.moneyWithoutCurrencyFormat);
      } else {
        this.cartOriginalTotal.classList.add(settings.classes.hidden);
      }

      this.cartTotal.innerHTML = data.total_price === 0 ? window.theme.translations.free : formatMoney(data.total_price, theme.moneyWithCurrencyFormat);

      if (data.cart_level_discount_applications.length > 0) {
        const discountsMarkup = this.buildCartTotalDiscounts(data.cart_level_discount_applications);

        this.cartDiscountHolder.classList.remove(settings.classes.hidden);
        this.cartDiscountHolder.innerHTML = discountsMarkup;
      } else {
        this.cartDiscountHolder.classList.add(settings.classes.hidden);
      }
    }
  }

  /**
   * Build cart total discounts
   *
   * @param   {Array}  discounts
   *
   * @return  {String}
   */

  buildCartTotalDiscounts(discounts) {
    let discountMarkup = '';
    const cartTotalDiscountsTemplateHtml = document.querySelector(settings.cartTotalDiscountsTemplate).innerHTML;

    discounts.forEach((discount) => {
      const discountTemplate = document.createElement('div');
      discountTemplate.innerHTML = cartTotalDiscountsTemplateHtml;
      const title = discountTemplate.querySelector(settings.elements.cartDiscountTitle);
      const price = discountTemplate.querySelector(settings.elements.cartDiscountPrice);
      title.textContent = discount.title;
      price.innerHTML = formatMoney(discount.total_allocated_amount, theme.moneyWithoutCurrencyFormat);

      discountMarkup += discountTemplate.innerHTML;
    });

    return discountMarkup;
  }

  /**
   * Set cart close position
   *
   * @return  {Void}
   */

  setCartClosePosition() {
    if (this.cartToggle) {
      const cartToggleTop = this.cartToggle.getBoundingClientRect().top;
      const containerPadding = 40;

      this.cartClose.style.top = `${cartToggleTop - containerPadding}px`;
    }
  }

  /**
   * Show/hide free shipping message
   *
   * @param   {Number}  total
   *
   * @return  {Void}
   */

  freeShippingMessageHandle(total) {
    if (this.cartMessage) {
      document.querySelectorAll(settings.elements.cartMessageItem).forEach((message) => {
        const hasFreeShipping = message.hasAttribute(settings.elements.cartMessage) && message.getAttribute(settings.elements.cartMessage) === 'true' && total !== 0;
        const cartMessageClass = hasFreeShipping ? settings.classes.defaultSuccess : settings.classes.hidden;

        message.classList.remove(settings.classes.hidden); // reset hidden state on change
        message.classList.toggle(cartMessageClass, total >= this.cartFreeLimitShipping || total === 0);
      });
    }
  }

  /**
   * Cart bar progress with message for free shipping
   *
   * @param   {Number}  progress
   *
   */
  cartBarProgress(progress = null) {
    const cartProgress = document.querySelectorAll(settings.elements.cartProgress);

    cartProgress.forEach((element) => {
      const progressPercentage = progress === null ? element.getAttribute('data-percent') : progress;
      element.style.setProperty('--percent', progressPercentage);
    });
  }

  /**
   * Update progress when update cart
   *
   * @return  {Void}
   */

  updateProgress() {
    const newPercentValue = (this.subtotal / this.cartFreeLimitShipping) * 100;
    const leftToSpend = formatMoney(this.cartFreeLimitShipping - this.subtotal, theme.settings.moneyFormat);

    document.querySelectorAll(settings.elements.leftToSpend).forEach((element) => {
      element.innerHTML = leftToSpend.replace('.00', '').replace(',00', '');
    });

    this.cartBarProgress(newPercentValue > 100 ? 100 : newPercentValue);
  }
}

window.cart = new CartDrawer();
