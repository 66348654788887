import getSiblings from '../util/siblings';

const selectors = {
  collapsibleTrigger: '[data-collapsible-trigger]',
  collapsibleContent: '[data-collapsible-content]',
  navHamburger: '[data-hamburger-scrollable]',
  header: 'data-header',
};

const attributes = {
  expanded: 'aria-expanded',
  controls: 'aria-controls',
  hidden: 'aria-hidden',
  accordion: 'data-accordion',
};

const classes = {
  isExpanded: 'is-expanded',
};

const sections = {};

class Collapsible {
  constructor(el) {
    this.section = el;
    this.triggers = this.section.querySelectorAll(selectors.collapsibleTrigger);
    this.resetHeight = 0;

    this.init();
  }

  init() {
    const navHamburger = document.querySelector(selectors.navHamburger);

    if (this.section.hasAttribute(selectors.header)) {
      this.triggers = [...this.triggers, ...navHamburger.querySelectorAll(selectors.collapsibleTrigger)];
    }

    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => this.collapsibleToggleEvent(event, trigger));
      trigger.addEventListener('keyup', (event) => this.collapsibleToggleEvent(event, trigger));
    });
  }

  collapsibleToggleEvent(e, trigger) {
    e.preventDefault();
    const dropdownId = trigger.getAttribute(attributes.controls);
    const isAccordion = trigger.hasAttribute(attributes.accordion);
    const dropdown = document.getElementById(dropdownId);
    const parent = trigger.parentNode;
    const isExpanded = parent.classList.contains(classes.isExpanded);
    const isSpace = e.keyCode === theme.keyboardKeys.SPACE;
    const isEscape = e.keyCode === theme.keyboardKeys.ESCAPE;
    let dropdownHeight = dropdown.querySelector(selectors.collapsibleContent).offsetHeight;

    // Do nothing if any different than ESC and Space key pressed
    if (e.keyCode && !isSpace && !isEscape) {
      return;
    }

    if (!isExpanded && isEscape) {
      return;
    }

    if (isExpanded) {
      setTimeout(() => {
        dropdownHeight = 0;
        this.setDropdownHeight(dropdown, dropdownHeight, isExpanded);
      }, 0);
    } else if (isAccordion) {
      const siblings = getSiblings(parent);
      siblings.forEach((sibling) => {
        if (sibling.classList.contains(classes.isExpanded)) {
          const trigger = sibling.querySelector(selectors.collapsibleTrigger);
          trigger.dispatchEvent(new Event('click'));
        }
      });
    }

    trigger.setAttribute(attributes.expanded, !isExpanded);
    parent.classList.toggle(classes.isExpanded, !isExpanded);

    this.setDropdownHeight(dropdown, dropdownHeight, isExpanded);
  }

  setDropdownHeight(dropdown, dropdownHeight, isExpanded) {
    dropdown.style.maxHeight = `${dropdownHeight}px`;
    dropdown.setAttribute(attributes.hidden, isExpanded);
    let maxHeight = 'none';

    if (this.resetHeight) {
      clearTimeout(this.resetHeight);
    }

    if (dropdownHeight === 0) {
      maxHeight = null;
    }

    this.resetHeight = setTimeout(() => {
      dropdown.style.maxHeight = maxHeight;
    }, 500);
  }
}

const collapsible = {
  onLoad() {
    sections[this.id] = new Collapsible(this.container);
  },
};

export {collapsible, Collapsible};
