const selectors = {
  aos: 'data-aos',
};

const removeAnimations = (container) => {
  const animatedElements = container.querySelectorAll(`[${selectors.aos}]`);
  animatedElements.forEach((element) => {
    element.removeAttribute(selectors.aos);
  });
};

export default removeAnimations;
