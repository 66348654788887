/* eslint-disable guard-for-in */
/**
 * Module to show Recently Viewed Products
 */
import showElement from '../util/show-element';

const selectors = {
  saleClass: ' is-sale',
  soldClass: ' is-sold-out',
  apiContent: '[data-api-content]',
  productTemplate: '[data-product-template]',
};

Shopify.Products = (function () {
  const config = {
    howManyToShow: 4,
    howManyToStoreInMemory: 10,
    wrapperId: 'RecentlyViewed',
    onComplete: null,
  };

  let productHandleQueue = [];
  let wrapper = null;
  let howManyToShowItems = null;

  const cookie = {
    configuration: {
      expires: 90,
      path: '/',
      domain: window.location.hostname,
    },
    name: 'shopify_recently_viewed',
    write: function (recentlyViewed) {
      const recentlyViewedString = recentlyViewed.join(' ');
      document.cookie = `${this.name}=${recentlyViewedString}; expires=${this.configuration.expires}; path=${this.configuration.path}; domain=${this.configuration.domain}`;
    },
    read: function () {
      let recentlyViewed = [];
      let cookieValue = null;
      const templateProduct = document.querySelector(selectors.productTemplate);

      if (document.cookie.indexOf('; ') !== -1 && document.cookie.split('; ').find((row) => row.startsWith(this.name))) {
        cookieValue = document.cookie
          .split('; ')
          .find((row) => row.startsWith(this.name))
          .split('=')[1];
      }

      if (cookieValue !== null) {
        recentlyViewed = cookieValue.split(' ');
      }

      if (templateProduct) {
        const currentProduct = templateProduct.getAttribute('data-product-handle');

        // Remove current product from the array
        if (recentlyViewed.indexOf(currentProduct) != -1) {
          const currentProductIndex = recentlyViewed.indexOf(currentProduct);
          recentlyViewed.splice(currentProductIndex, 1);
        }
      }

      return recentlyViewed;
    },
    destroy: function () {
      const cookieVal = null;
      document.cookie = `${this.name}=${cookieVal}; expires=${this.configuration.expires}; path=${this.configuration.path}; domain=${this.configuration.domain}`;
    },
    remove: function (productHandle) {
      const recentlyViewed = this.read();
      const position = recentlyViewed.indexOf(productHandle);
      if (position !== -1) {
        recentlyViewed.splice(position, 1);
        this.write(recentlyViewed);
      }
    },
  };

  const finalize = () => {
    showElement(wrapper, true);
    const cookieItemsLength = cookie.read().length;

    if (Shopify.recentlyViewed && howManyToShowItems && cookieItemsLength && cookieItemsLength < howManyToShowItems && wrapper.children.length) {
      let allClassesArr = [];
      let addClassesArr = [];
      let objCounter = 0;

      for (const property in Shopify.recentlyViewed) {
        objCounter += 1;
        const objString = Shopify.recentlyViewed[property];
        const objArr = objString.split(' ');
        const propertyIdx = parseInt(property.split('_')[1]);
        allClassesArr = [...allClassesArr, ...objArr];

        if (cookie.read().length === propertyIdx || (objCounter === Object.keys(Shopify.recentlyViewed).length && !addClassesArr.length)) {
          addClassesArr = [...addClassesArr, ...objArr];
        }
      }

      for (let i = 0; i < wrapper.children.length; i++) {
        const element = wrapper.children[i];
        if (allClassesArr.length) {
          element.classList.remove(...allClassesArr);
        }

        if (addClassesArr.length) {
          element.classList.add(...addClassesArr);
        }
      }
    }

    // If we have a callback.
    if (config.onComplete) {
      try {
        config.onComplete();
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  const moveAlong = (shown) => {
    if (productHandleQueue.length && shown < config.howManyToShow) {
      fetch('/products/' + productHandleQueue[0] + '?section_id=api-product-grid-item')
        .then((response) => response.text())
        .then((product) => {
          const fresh = document.createElement('div');
          fresh.innerHTML = product;

          wrapper.innerHTML += fresh.querySelector(selectors.apiContent).innerHTML;

          productHandleQueue.shift();
          shown++;
          moveAlong(shown);
        })
        .catch(() => {
          cookie.remove(productHandleQueue[0]);
          productHandleQueue.shift();
          moveAlong(shown);
        });
    } else {
      finalize();
    }
  };

  return {
    showRecentlyViewed: function (params) {
      const paramsNew = params || {};
      const shown = 0;

      // Update defaults.
      Object.assign(config, paramsNew);

      // Read cookie.
      productHandleQueue = cookie.read();

      // Template and element where to insert.
      wrapper = document.querySelector(`#${config.wrapperId}`);

      // How many products to show.
      howManyToShowItems = config.howManyToShow;
      config.howManyToShow = Math.min(productHandleQueue.length, config.howManyToShow);

      // If we have any to show.
      if (config.howManyToShow && wrapper) {
        // Getting each product with an Ajax call and rendering it on the page.
        moveAlong(shown);
      }
    },

    getConfig: function () {
      return config;
    },

    clearList: function () {
      cookie.destroy();
    },

    recordRecentlyViewed: function (params) {
      const paramsNew = params || {};

      // Update defaults.
      Object.assign(config, paramsNew);

      // Read cookie.
      let recentlyViewed = cookie.read();

      // If we are on a product page.
      if (window.location.pathname.indexOf('/products/') !== -1) {
        // What is the product handle on this page.
        const productHandle = decodeURIComponent(window.location.pathname)
          .match(
            /\/products\/([a-z0-9\-]|[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|[\u203B]|[\w\u0430-\u044f]|[\u0400-\u04FF]|[\u0900-\u097F]|[\u0590-\u05FF\u200f\u200e]|[\u0621-\u064A\u0660-\u0669 ])+/
          )[0]
          .split('/products/')[1];
        // In what position is that product in memory.
        const position = recentlyViewed.indexOf(productHandle);

        // If not in memory.
        if (position === -1) {
          // Add product at the start of the list.
          recentlyViewed.unshift(productHandle);
          // Only keep what we need.
          recentlyViewed = recentlyViewed.splice(0, config.howManyToStoreInMemory);
        } else {
          // Remove the product and place it at start of list.
          recentlyViewed.splice(position, 1);
          recentlyViewed.unshift(productHandle);
        }

        // Update cookie.
        cookie.write(recentlyViewed);
      }
    },

    hasProducts: cookie.read().length > 0,
  };
})();
