import Ajaxinate from 'ajaxinate';
import {makeGridSwatches} from '../features/swatch';
import {Quickview} from './quickview';

const selectors = {
  infinityContainer: '[data-infinity]',
  pagination: '[data-pagination]',
  collectionBlockImage: '[data-product-image]',
  dataId: 'data-section-id',
};

let sections = {};

class Ajaxify {
  constructor(container) {
    this.container = container;
    this.infinityContainer = this.container.querySelector(selectors.infinityContainer);
    this.endlessScroll = null;

    if (this.infinityContainer) {
      this.init();
    }
  }

  init() {
    const id = this.container.getAttribute(selectors.dataId);
    this.fix();
    this.endlessScroll = new Ajaxinate({
      container: `section[${selectors.dataId}="${id}"] ${selectors.infinityContainer}`,
      pagination: `section[${selectors.dataId}="${id}"] ${selectors.pagination}`,
      callback: () => {
        makeGridSwatches(this.container);
        new Quickview(this.container);
      },
    });
  }

  // Fix ajaxinate in theme editor
  fix() {
    Ajaxinate.prototype.loadMore = function loadMore() {
      this.request = new XMLHttpRequest();

      this.request.onreadystatechange = function success() {
        if (!this.request.responseXML) {
          return;
        }
        if (!this.request.readyState === 4 || !this.request.status === 200) {
          return;
        }

        const newContainer = this.request.responseXML.querySelector(this.settings.container);
        const newPagination = this.request.responseXML.querySelector(this.settings.pagination);

        this.containerElement.insertAdjacentHTML('beforeend', newContainer.innerHTML);

        if (typeof newPagination === 'undefined' || newPagination === null) {
          this.removePaginationElement();
        } else {
          this.paginationElement.innerHTML = newPagination.innerHTML;

          if (this.settings.callback && typeof this.settings.callback === 'function') {
            this.settings.callback(this.request.responseXML);
          }

          this.initialize();
        }
      }.bind(this);

      this.request.open('GET', this.nextPageUrl, true);
      this.request.responseType = 'document';
      this.request.send();
    };
  }

  unload() {
    if (this.endlessScroll) {
      this.endlessScroll.destroy();
    }
  }
}

const ajaxify = {
  onLoad() {
    sections = new Ajaxify(this.container);
  },
  onUnload: function () {
    if (typeof sections.unload === 'function') {
      sections.unload();
    }
  },
};

export {ajaxify, Ajaxify};
