import outerHeight from '../features/outer-height-margins';

const selectors = {
  bannerContainer: '[data-banner-container]',
  bannerContent: '[data-banner-content]',
};

function preventOverflow(container) {
  const banners = container.querySelectorAll(selectors.bannerContainer);

  if (banners) {
    banners.forEach((banner) => {
      const content = banner.querySelector(selectors.bannerContent);

      if (content) {
        banner.style.minHeight = `${outerHeight(content)}px`;

        document.addEventListener('theme:resize', () => {
          banner.style.minHeight = `${outerHeight(content)}px`;
        });
      }
    });
  }
}

export default preventOverflow;
