import {popoutSection} from '../features/popout';
import {newsletterCheckForResultSection} from '../globals/newsletter';
import {register} from '../vendor/theme-scripts/theme-sections';

const footerSection = {
  onLoad() {
    // Lighthouse fires security warning for the Shopify link.
    var shopifyLink = document.querySelector('[data-powered-link] a');
    if (shopifyLink) {
      shopifyLink.setAttribute('rel', 'noopener');
    }
  },
};

register('footer', [popoutSection, footerSection, newsletterCheckForResultSection]);
