import QuantityCounter from '../globals/quantity-handle';
import Media from '../media/media';
import {getHeight} from '../globals/height';
import {Swatch} from '../features/swatch';
import * as a11y from '../vendor/theme-scripts/theme-a11y';

import {ProductAddForm} from './product-form';

const selectors = {
  id: 'id',
  apiContent: '[data-api-content]',
  container: '[data-section-id]',
  collectionSlider: '[data-collection-slider]',
  collectionSliderWrapper: '[data-collection-slider-wrapper]',
  quickviewWrap: '[data-quickview-wrap]',
  quickviewId: 'data-quickview-id',
  quickviewClose: '[data-quickview-close]',
  featuredBlock: '[data-collection-featured-block]',
  productBlock: '[data-product-block]',
  productContainer: '[data-product-container]',
  productImage: '[data-product-single-media-wrapper]',
  addToCart: '[data-add-to-cart]',
  handle: 'data-trigger-quickview',
  productSlider: '[data-product-slideshow]',
  swatch: '[data-swatch]',
  videoLoop: 'data-video-looping',
};

const classes = {
  isLoading: 'is-loading',
  isLoaded: 'is-loaded',
  isSelected: 'is-selected',
  isVisible: 'is-visible',
  isQuickviewOpen: 'is-quickview-open',
  focusEnabled: 'is-focused',
  sliderEnabled: 'flickity-enabled',
  mediaHidden: 'media--hidden',
  buttonAlt: 'button--alt',
};

/**
 * Button constructor, triggered for each button by Quickview
 */
export default class QuickviewButton {
  constructor(button, id) {
    this.button = button;
    this.id = id;
    this.container = this.button.closest(selectors.container);
    this.collectionSliderWrapper = this.container.querySelector(selectors.collectionSliderWrapper);
    this.layoutCarousel = this.container.querySelector(selectors.collectionSlider);
    this.handle = this.button.getAttribute(selectors.handle);
    this.productBlock = this.button.closest(selectors.productBlock);
    this.quickview = null;
    this.quickviewWrap = null;
    this.quickviewClose = null;
    this.quickviewWrapHeight = 0;
    this.isQuickViewLoading = false;
    this.accessibility = a11y;
    this.swatches = [];
    this.form = {};
    this.init();
  }

  init() {
    this.initButton();
    this.initFetch();
  }

  initFetch() {
    this.productBlock.addEventListener(
      'theme:quickview:open',
      () => {
        this.renderProduct();
      },
      false
    );
  }

  renderProduct() {
    fetch(`${theme.routes.root}products/${this.handle}?section_id=api-quickview`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const fresh = document.createElement('div');
        fresh.innerHTML = data.replaceAll('||product-handle||', this.handle);
        const uniq = fresh.querySelector(selectors.quickviewWrap).dataset.quickviewId;

        if (this.layoutCarousel && this.collectionSliderWrapper) {
          this.collectionSliderWrapper.insertAdjacentHTML('beforeend', fresh.querySelector(selectors.apiContent).innerHTML);
        } else {
          this.productBlock.insertAdjacentHTML('beforeend', fresh.querySelector(selectors.apiContent).innerHTML);
        }

        this.productBlock.classList.add(classes.isLoaded);

        this.quickviewWrap = this.container.querySelector(`[${selectors.quickviewId}="${uniq}"]`);
        this.quickviewClose = this.quickviewWrap.querySelector(selectors.quickviewClose);
        this.quickviewWrapHeight = this.quickviewWrap.querySelector(selectors.productContainer).offsetHeight;
        this.quickview = this.quickviewWrap.parentNode;

        this.onLoaded();
        this.show();
      })
      .catch(function (error) {
        console.log('error: ', error);
      });
  }

  initButton() {
    const initButtons = this.productBlock.querySelectorAll(`[${selectors.handle}='${this.handle}']`);

    if (initButtons.length) {
      initButtons.forEach((element) => {
        element.addEventListener('click', (e) => {
          e.preventDefault();

          const productBlockLoaded = this.productBlock.classList.contains(classes.isLoaded);
          const productBlockVisible = this.productBlock.classList.contains(classes.isQuickviewOpen);
          const siblingsVisible = this.productBlock.parentNode.querySelector(`.${classes.isQuickviewOpen}`) !== null;
          const eventQuickview = new CustomEvent('theme:quickview:open', {
            handle: this.handle,
          });

          if (!this.isQuickViewLoading) {
            this.isQuickViewLoading = true;

            if (productBlockLoaded && productBlockVisible) {
              // if loaded and visible
              this.hide();
            } else if (productBlockLoaded && !productBlockVisible && !siblingsVisible) {
              // if loaded but not visible, no other quickViews open
              this.show();
            } else if (productBlockLoaded && !productBlockVisible && siblingsVisible) {
              // if loaded and not visible, other quickViews are open
              this.hide();
              setTimeout(() => {
                this.show();
              }, 200);
            } else if (siblingsVisible) {
              // if not loaded yet, other quickViews open
              this.hide();
              setTimeout(() => {
                this.productBlock.dispatchEvent(eventQuickview);
              }, 200);
            } else {
              // if not loaded yet, no other quickViews open
              this.productBlock.dispatchEvent(eventQuickview);
            }
          }
        });
      });
    }
  }

  show() {
    const sub = (window.innerHeight - this.quickviewWrapHeight) / 2;
    const offset = this.quickviewWrap.getBoundingClientRect().top + window.scrollY;
    const scrollPosition = offset - sub - theme.dimensions.headerScrolled;
    const featuredBlockHeight = getHeight(selectors.featuredBlock);
    const isFocusEnabled = document.body.classList.contains(classes.focusEnabled);
    const quickViewPattern = 'api-quickview';
    const quickViewIds = this.productBlock.querySelectorAll(`[id*="${quickViewPattern}"]`);
    const quickViewFors = this.productBlock.querySelectorAll(`[for*="${quickViewPattern}"]`);
    document.documentElement.style.setProperty('--collection-featured-block-height', `${featuredBlockHeight}px`);

    quickViewIds.forEach((element) => {
      const uniqueId = element.id.replace(quickViewPattern, this.id);
      element.id = uniqueId;
    });

    quickViewFors.forEach((element) => {
      const uniqueFor = element.getAttribute('for').replace(quickViewPattern, this.id);
      element.setAttribute('for', uniqueFor);
    });

    this.quickview.classList.remove(classes.isLoading);

    window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    });

    this.productBlock.classList.add(classes.isQuickviewOpen);
    this.quickview.classList.add(classes.isVisible);
    this.isQuickViewLoading = false;

    if (isFocusEnabled) {
      this.accessibility.trapFocus(this.quickviewWrap, {
        elementToFocus: this.quickviewClose,
      });
    }
  }

  hide() {
    const productBlocks = this.productBlock.parentNode.querySelectorAll(selectors.productBlock);
    const isFocusEnabled = document.body.classList.contains(classes.focusEnabled);
    productBlocks.forEach((productBlock) => {
      if (productBlock.classList.contains(classes.isQuickviewOpen)) {
        const visibleImage = productBlock.querySelector(`${selectors.productImage}.${classes.isSelected}`);
        const uniq = productBlock.dataset.quickviewElement;
        const quickviewWrap = this.container.querySelector(`[${selectors.quickviewId}="${uniq}"]`);
        const quickview = quickviewWrap.parentNode;
        if (visibleImage !== null) {
          visibleImage.dispatchEvent(new CustomEvent('theme:media:hidden'));
          visibleImage.classList.remove(classes.mediaHidden);
        }

        if (quickview) {
          quickview.classList.remove(classes.isVisible, classes.isLoading);
        }

        productBlock.classList.remove(classes.isQuickviewOpen);
      }
    });

    this.isQuickViewLoading = false;
    this.accessibility.removeTrapFocus();

    if (isFocusEnabled) {
      const button = document.querySelector(`[${selectors.handle}="${this.handle}"]`);

      setTimeout(() => {
        document.documentElement.style.setProperty('--collection-featured-block-height', 'none');
        button.focus();
      }, 300);
    }
  }

  onLoaded() {
    const sectionId = `${this.id}-${this.quickviewWrap.getAttribute(selectors.quickviewId)}`;
    const slider = this.quickviewWrap.querySelector(selectors.productSlider);
    const hasSlider = slider.classList.contains(classes.sliderEnabled);

    const section = {
      id: sectionId,
      container: this.quickviewWrap,
      type: 'quickview',
    };

    if (theme.settings.enableVideoLooping) {
      this.quickviewWrap.setAttribute(selectors.videoLoop, true);
    }

    if (hasSlider) {
      window.dispatchEvent(new Event('resize'));
    } else if (typeof theme.mediaInstances[this.id] === 'undefined') {
      theme.mediaInstances[sectionId] = new Media(section);
      theme.mediaInstances[sectionId].init();
    } else {
      theme.mediaInstances[sectionId].initSlider();
    }

    this.form = new ProductAddForm(section);

    if (theme.settings.showQuantity) {
      const counter = new QuantityCounter(section.container);
      counter.init();
    }

    const swatches = section.container.querySelectorAll(selectors.swatch);
    swatches.forEach((swatch) => {
      this.swatches.push(new Swatch(swatch));
    });

    this.quickviewClose.addEventListener('click', (e) => {
      e.preventDefault();
      this.hide();
    });

    document.addEventListener('keyup', (event) => {
      if (event.keyCode === theme.keyboardKeys.ESCAPE && document.querySelectorAll(`${selectors.productBlock}.${classes.isVisible}`).length) {
        this.hide();
      }
    });

    const event = new CustomEvent('theme:quickview:loaded', {
      bubbles: true,
    });

    this.productBlock.dispatchEvent(event);

    this.initPaymentButton();
  }

  initPaymentButton() {
    const enablePaymentButton = theme.settings.enablePaymentButton;
    const enableAcceptTerms = theme.settings.enableAcceptTerms;
    const addToCart = this.quickviewWrap.querySelector(selectors.addToCart);

    if (enablePaymentButton && !enableAcceptTerms) {
      addToCart.classList.add(classes.buttonAlt);

      if (typeof Shopify !== 'undefined' && typeof Shopify.PaymentButton !== 'undefined' && typeof Shopify.PaymentButton.init !== 'undefined') {
        Shopify.PaymentButton.init();
      }
    } else {
      addToCart.classList.remove(classes.buttonAlt);
    }
  }
}