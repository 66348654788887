import {Quickview} from '../features/quickview';
import {makeGridSwatches} from '../features/swatch';
import {register} from '../vendor/theme-scripts/theme-sections';

const sections = {};

const selectors = {
  recentlyViewed: '#RecentlyViewed',
  dataLimit: 'data-limit',
  productBlock: '[data-product-block]',
  productImage: '[data-product-image]',
};

class RecentlyViewedProducts {
  constructor(section) {
    this.section = section;
    this.container = section.container;
    this.limit = parseInt(this.container.getAttribute(selectors.dataLimit));
    this.recentlyViewed = this.container.querySelector(selectors.recentlyViewed);

    if (this.recentlyViewed) {
      this.init();
    }
  }

  init() {
    Shopify.Products.showRecentlyViewed({
      howManyToShow: this.limit,
      onComplete: () => {
        const recentlyViewedProducts = this.container.querySelectorAll(selectors.productBlock);

        if (recentlyViewedProducts.length > 0) {
          makeGridSwatches(this.container);
          new Quickview(this.container);
        }
      },
    });
  }
}

const recentlyViewedProductsSection = {
  onLoad() {
    sections[this.id] = new RecentlyViewedProducts(this);
  },
};

register('recently-viewed-products', recentlyViewedProductsSection);
