import Flickity from 'flickity';
import removeAnimations from '../features/remove-animations';
import {swatchGridSection} from '../features/swatch';
import {quickviewSection} from '../features/quickview';
import {register} from '../vendor/theme-scripts/theme-sections';

const sections = {};

const selectors = {
  slider: '[data-collection-slider]',
  prevArrow: '[data-prev-arrow]',
  nextArrow: '[data-next-arrow]',
  productBlock: '[data-product-block]',
  productImage: '[data-product-image]',
  quickviewId: 'data-quickview-id',
  quickviewWrap: '[data-quickview-wrap]',
  quickviewButton: '[data-trigger-quickview]',
};

const classes = {
  isVisible: 'is-visible',
  flickityEnabled: 'flickity-enabled',
  isQuickviewOpen: 'is-quickview-open',
};

class CollectionSlider {
  constructor(section) {
    this.container = section.container;
    this.slideshow = this.container.querySelector(selectors.slider);
    this.productBlocks = this.container.querySelectorAll(selectors.productBlock);
    this.productImages = this.container.querySelectorAll(selectors.productImage);
    this.slideshowPrev = this.container.querySelector(selectors.prevArrow);
    this.slideshowNext = this.container.querySelector(selectors.nextArrow);
    this.flkty = null;
    this.resizeEvents = () => {
      this.sliderInit();
      this.setArrowPosition();
      this.setQuickViewPosition();
    };

    if (this.slideshow && this.productBlocks.length) {
      this.init();
    }
  }

  init() {
    this.slideshowPrev.addEventListener('click', this.goToPrevSlide.bind(this));
    this.slideshowNext.addEventListener('click', this.goToNextSlide.bind(this));

    removeAnimations(this.container);

    this.sliderInit();

    this.productBlocks.forEach((productBlock) => {
      productBlock.addEventListener('theme:quickview:loaded', () => {
        productBlock.classList.add(classes.isQuickviewOpen);
        this.setQuickViewPosition();
      });
    });

    document.addEventListener('theme:resize', this.resizeEvents);
  }

  sliderInit() {
    const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

    if (window.innerWidth >= theme.sizes.small) {
      if (!sliderInitialized) {
        this.flkty = new Flickity(this.slideshow, {
          groupCells: '100%',
          autoPlay: false,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: false,
          cellAlign: 'left',
          contain: true,
          on: {
            ready: () => {
              this.setArrowPosition();
            },
            change: function () {
              const visibleQuickView = this.element.querySelector(`${selectors.productBlock}.${classes.isVisible}`);

              if (visibleQuickView !== null) {
                visibleQuickView.querySelector(selectors.quickviewButton).dispatchEvent(new Event('click'));
              }
            },
          },
        });
      }
    } else if (sliderInitialized) {
      this.flkty.destroy();
    }
  }

  goToPrevSlide(e) {
    e.preventDefault();

    this.flkty.previous(true);
  }

  goToNextSlide(e) {
    e.preventDefault();

    this.flkty.next(true);
  }

  setArrowPosition() {
    const productBlock = this.slideshow.querySelector(selectors.productImage);
    if (!productBlock) return;

    let arrowTop = productBlock.offsetHeight / 2;
    const arrowHeight = this.slideshowPrev.offsetHeight / 2;
    arrowTop -= arrowHeight;
    this.slideshowPrev.style.top = `${arrowTop}px`;
    this.slideshowNext.style.top = `${arrowTop}px`;
  }

  setQuickViewPosition() {
    // Get the product block that has Quick view opened
    const productBlock = this.slideshow.querySelector(`.${classes.isQuickviewOpen}`);

    if (productBlock === null) {
      return;
    }

    const uniq = productBlock.dataset.quickviewElement;
    const quickviewWrap = this.container.querySelector(`[${selectors.quickviewId}="${uniq}"]`);
    const sliderPadding = parseInt(window.getComputedStyle(this.slideshow).paddingLeft.replace('px', ''));
    const sliderInnerWidth = this.slideshow.offsetWidth - sliderPadding * 2;

    if (quickviewWrap) {
      let offsetLeft = productBlock.offsetLeft;

      if (offsetLeft >= sliderInnerWidth) {
        offsetLeft %= sliderInnerWidth;
      }

      quickviewWrap.style.left = `calc(${productBlock.style.left} - ${offsetLeft}px)`;
      quickviewWrap.style.width = '100%';
    }
  }

  onUnload() {
    if (this.slideshow) {
      const sliderInitialized = this.slideshow.classList.contains(classes.flickityEnabled);

      if (sliderInitialized) {
        this.flkty.destroy();
      }
    }

    document.removeEventListener('theme:resize', this.resizeEvents);
  }
}

const FeaturedCollectionSection = {
  onLoad() {
    sections[this.id] = new CollectionSlider(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
};

register('featured-collection', [FeaturedCollectionSection, quickviewSection, swatchGridSection]);
