import {quantitySelectors} from '../features/quantity-selectors';
import throttle from '../util/throttle';
import {register} from '../vendor/theme-scripts/theme-sections';
import Media from '../media/media';
import {swatchSection} from '../features/swatch';
import {productFormSection} from '../features/product-form';
import {ComplementaryProducts} from '../features/complementary-products';
import {RecipientForm} from '../features/recipient-form';

const selectors = {
  productContainer: '[data-product-container]',
  addToCart: '[data-add-to-cart]',
  shopBar: '[data-shop-bar]',
  productJson: '[data-product-json]',
  form: '[data-product-form-container]',
  dropdown: '[data-single-option-selector]',
  footer: '[data-footer]',
  colorLabel: '[data-color-label]',
  colorSwatch: '[data-color-swatch]',
  dataOption: '[data-option]',
  dataSectionId: 'data-section-id',
  selectTag: 'select',
  dataPosition: 'data-position',
  dataIndex: 'data-index',
};

const classes = {
  onboarding: 'onboarding-product',
  shopBarVisible: 'shop-bar--is-visible',
  footerPush: 'site-footer--push',
};

let sections = {};

/**
 * Product section constructor.
 * @param {string} container - selector for the section container DOM element
 */
class Product {
  constructor(section) {
    this.section = section;
    this.container = section.container;
    this.id = this.container.getAttribute(selectors.dataSectionId);
    this.productContainer = this.container.querySelector(selectors.productContainer);
    this.footer = document.querySelector(selectors.footer);
    this.onboarding = this.productContainer.classList.contains(classes.onboarding);
    this.shopBar = document.querySelector(selectors.shopBar);
    this.scrollEvent = throttle(() => this.shopBarShow(), 100);

    if (!this.onboarding) {
      // Stop parsing if we don't have the product json script tag
      // when loading section in the Theme Editor
      const productJson = this.container.querySelector(selectors.productJson);
      if ((productJson && !productJson.innerHTML) || !productJson) {
        return;
      }

      // Record recently viewed products when the product page is loading
      Shopify.Products.recordRecentlyViewed();

      this.form = this.container.querySelector(selectors.form);

      this.init();

      if (this.shopBar) {
        this.initShopBar();
      }
    } else {
      quantitySelectors();

      this.productContainer.querySelectorAll(selectors.colorSwatch).forEach((swatch) => {
        swatch.addEventListener('change', (e) => {
          this.updateColorName(e);
        });
      });
    }
  }

  init() {
    theme.mediaInstances[this.id] = new Media(this.section);
    theme.mediaInstances[this.id].init();
  }

  initShopBar() {
    const cartBarSelectors = this.shopBar.querySelectorAll(selectors.selectTag);
    const formSelectors = this.form.querySelectorAll(selectors.dropdown);
    const submit = this.shopBar.querySelector(selectors.addToCart);

    // Prevent shopbar submit
    submit.addEventListener('click', (e) => {
      e.preventDefault();
    });

    if (cartBarSelectors.length) {
      cartBarSelectors.forEach((element) => {
        // Update product form on cart bar variant change
        element.addEventListener('change', () => {
          const index = element.getAttribute(selectors.dataIndex);
          const optionSelected = element.value;
          const targets = this.form.querySelectorAll(`${selectors.dropdown}[${selectors.dataIndex}="${index}"]`);

          if (targets[0].tagName === 'INPUT') {
            for (const target of targets) {
              const targetIndex = target.getAttribute(selectors.dataIndex);
              const targetValue = target.value;
              if (targetIndex === index && targetValue === optionSelected) {
                target.checked = true;
                target.dispatchEvent(new Event('change'));
                break;
              }
            }
          } else {
            const select = targets[0];
            select.value = optionSelected;
            select.dispatchEvent(new Event('change'));
          }
        });
      });
    }

    // Update cart bar on product form variant change
    if (formSelectors.length) {
      formSelectors.forEach((element) => {
        element.addEventListener('change', () => {
          const index = element.getAttribute(selectors.dataIndex);
          const optionSelected = element.value;

          this.shopBar.querySelector(`[${selectors.dataIndex}="${index}"]`).value = optionSelected;
        });
      });
    }

    this.shopBarShow();
    window.addEventListener('scroll', this.scrollEvent);
  }

  shopBarShow() {
    const scrolled = window.scrollY;
    const productContainerTop = this.productContainer.getBoundingClientRect().top + scrolled;

    if (scrolled > productContainerTop) {
      this.shopBar.classList.add(classes.shopBarVisible);
      this.footer.classList.add(classes.footerPush);
    } else if (scrolled < productContainerTop - theme.dimensions.headerScrolled) {
      this.shopBar.classList.remove(classes.shopBarVisible);
      this.footer.classList.remove(classes.footerPush);
    }
  }

  updateColorName(evt) {
    const target = evt.target;
    const optionLabel = target.closest(selectors.dataOption).querySelector(selectors.colorLabel);

    if (target.tagName === 'INPUT' && optionLabel !== null) {
      optionLabel.innerText = target.value;
    }
  }

  unload() {
    window.removeEventListener('scroll', this.scrollEvent);
  }
}

const productSection = {
  onLoad() {
    sections = new Product(this);
  },
  onUnload: function () {
    if (typeof sections.unload === 'function') {
      sections.unload();
    }
  },
};

register('product', [productFormSection, productSection, swatchSection]);

if (!customElements.get('complementary-products')) {
  customElements.define('complementary-products', ComplementaryProducts);
}

if (!customElements.get('recipient-form')) {
  customElements.define('recipient-form', RecipientForm);
}
