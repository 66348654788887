const selectors = {
  quantityAdjust: '[data-qty-adjust]',
  quantityAdjustPlus: 'data-qty-adjust-plus',
  quantityAdjustMinus: 'data-qty-adjust-minus',
  quantityNum: '[data-qty]',
};

const quantity = (e, element) => {
  if (e.type === 'keyup' && e.keyCode !== 13) {
    return;
  }

  const el = element;
  const qtySelector = el.parentElement.querySelector(selectors.quantityNum);
  let qty = parseInt(qtySelector.value.replace(/\D/g, ''));

  // Make sure we have a valid integer
  if (parseFloat(qty) == parseInt(qty) && !isNaN(qty)) {
    // We have a number!
  } else {
    // Not a number. Default to 1.
    qty = 1;
  }
  // Add or subtract from the current quantity
  if (el.hasAttribute(selectors.quantityAdjustPlus)) {
    qty += 1;
  } else {
    qty -= 1;
    if (qty <= 1) {
      qty = 1;
    }
  }
  // Update the input's number
  qtySelector.value = qty;
  qtySelector.dispatchEvent(new Event('click'));
};

export function quantitySelectors() {
  // Setup listeners to add/subtract from the input
  const quantityAdjust = document.querySelectorAll(selectors.quantityAdjust);
  quantityAdjust.forEach((element) => {
    element.addEventListener('click', function (e) {
      quantity(e, element);
    });
    element.addEventListener('keyup', function (e) {
      quantity(e, element);
    });
  });

  // All quantity input value change on up/down arrows key press
  const quantityNum = document.querySelectorAll(selectors.quantityNum);
  quantityNum.forEach((element) => {
    element.addEventListener('keyup', function (e) {
      // On arrow up key press (38)
      if (e.keyCode == 38) {
        // this.parentElement.querySelector(`.${selectors.quantityAdjustPlus}`).dispatchEvent(new Event('click'));
      }
      // On arrow down key press (40)
      if (e.keyCode == 40) {
        // this.parentElement.querySelector(`.${selectors.quantityAdjustMinus}`).dispatchEvent(new Event('click'));
      }
    });
  });
}
