import {LoadPhotoswipe} from '../features/load-photoswipe';

const selectors = {
  productContainer: '[data-product-container]',
  productSlideshow: '[data-product-slideshow]',
  zoomWrapper: '[data-zoom-wrapper]',
  dataImageSrc: 'data-image-src',
  dataImageWidth: 'data-image-width',
  dataImageHeight: 'data-image-height',
  dataImageZoomEnable: 'data-lightbox',
};

const classes = {
  popupClass: 'pswp-zoom-gallery',
  popupClassNoThumbs: 'pswp-zoom-gallery--single',
  isMoving: 'is-moving',
};

class Zoom {
  constructor(section) {
    this.container = section.container;
    this.productContainer = this.container.querySelector(selectors.productContainer);
    this.slideshow = this.container.querySelector(selectors.productSlideshow);
    this.zoomWrappers = this.container.querySelectorAll(selectors.zoomWrapper);
    this.zoomEnable = this.productContainer.getAttribute(selectors.dataImageZoomEnable) === 'true';

    if (this.zoomEnable) {
      this.init();
    }
  }

  init() {
    if (this.zoomWrappers.length) {
      this.zoomWrappers.forEach((element, i) => {
        element.addEventListener('click', (e) => {
          e.preventDefault();

          const isMoving = this.slideshow && this.slideshow.classList.contains(classes.isMoving);
          theme.a11yTrigger = element;

          if (!isMoving) {
            this.createZoom(i);
          }
        });

        element.addEventListener('keyup', (e) => {
          // On keypress Enter move the focus to the first focusable element in the related slide
          if (e.keyCode === theme.keyboardKeys.ENTER) {
            e.preventDefault();

            element.dispatchEvent(new Event('click'));
          }
        });
      });
    }
  }

  createZoom(indexImage) {
    const instance = this;
    let items = [];
    let counter = 0;

    this.zoomWrappers.forEach((elementImage) => {
      const imgSrc = elementImage.getAttribute('href');
      const imgWidth = parseInt(elementImage.getAttribute(selectors.dataImageWidth));
      const imgHeight = parseInt(elementImage.getAttribute(selectors.dataImageHeight));

      items.push({
        src: imgSrc,
        w: imgWidth,
        h: imgHeight,
        msrc: imgSrc,
      });

      counter += 1;
      if (instance.zoomWrappers.length === counter) {
        let popupClass = `${classes.popupClass}`;
        if (counter === 1) {
          popupClass = `${classes.popupClass} ${classes.popupClassNoThumbs}`;
        }
        const options = {
          barsSize: {top: 0, bottom: 'auto'},
          history: false,
          focus: false,
          index: indexImage,
          mainClass: popupClass,
          showHideOpacity: true,
          showAnimationDuration: 250,
          hideAnimationDuration: 250,
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          captionEl: false,
          closeEl: true,
          closeElClasses: ['caption-close'],
          tapToClose: false,
          clickToCloseNonZoomable: false,
          maxSpreadZoom: 2,
          loop: true,
          spacing: 0,
          allowPanToNext: true,
          pinchToClose: false,
        };

        new LoadPhotoswipe(items, options);
      }
    });
  }
}

export default Zoom;
