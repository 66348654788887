import * as a11y from '../vendor/theme-scripts/theme-a11y';

const selectors = {
  list: '[data-store-availability-list]',
};

const defaults = {
  close: '.js-modal-close',
  open: '.js-modal-open-store-availability-modal',
  openClass: 'modal--is-active',
  openBodyClass: 'modal--is-visible',
  closeModalOnClick: false,
};

class Modals {
  constructor(id, options) {
    this.modal = document.getElementById(id);
    this.accessibility = a11y;

    if (!this.modal) {
      return false;
    }

    this.nodes = {
      parents: [document.querySelector('html'), document.body],
    };

    this.config = Object.assign(defaults, options);

    this.modalIsOpen = false;

    this.focusOnOpen = this.config.focusOnOpen ? document.getElementById(this.config.focusOnOpen) : this.modal;

    this.openElement = document.querySelector(this.config.open);
    this.init();
  }

  init() {
    this.openElement.addEventListener('click', this.open.bind(this));

    this.modal.querySelector(this.config.close).addEventListener('click', this.closeModal.bind(this));
  }

  open(evt) {
    var self = this;

    // Keep track if modal was opened from a click, or called by another function
    var externalCall = false;

    if (this.modalIsOpen) {
      return;
    }

    // Prevent following href if link is clicked
    if (evt) {
      evt.preventDefault();
    } else {
      externalCall = true;
    }

    // Without this, the modal opens, the click event bubbles up
    // which closes the modal.
    if (evt && evt.stopPropagation) {
      evt.stopPropagation();
    }

    if (this.modalIsOpen && !externalCall) {
      this.closeModal();
    }

    this.modal.classList.add(this.config.openClass);

    this.nodes.parents.forEach(function (node) {
      node.classList.add(self.config.openBodyClass);
    });

    this.modalIsOpen = true;

    // Scroll lock
    this.scrollableElement = document.querySelector(selectors.list);
    document.dispatchEvent(new CustomEvent('theme:scroll:lock', {bubbles: true, detail: this.scrollableElement}));

    this.accessibility.trapFocus(this.modal);

    this.bindEvents();
  }

  closeModal() {
    if (!this.modalIsOpen) {
      return;
    }

    document.activeElement.blur();

    this.modal.classList.remove(this.config.openClass);

    var self = this;

    document.dispatchEvent(new CustomEvent('theme:scroll:unlock', {bubbles: true}));

    this.nodes.parents.forEach(function (node) {
      node.classList.remove(self.config.openBodyClass);
    });

    this.modalIsOpen = false;

    this.accessibility.removeTrapFocus();

    this.openElement.focus();

    this.unbindEvents();
  }

  bindEvents() {
    this.keyupHandler = this.keyupHandler.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    document.body.addEventListener('keyup', this.keyupHandler);
    document.body.addEventListener('click', this.clickHandler);
  }

  unbindEvents() {
    document.body.removeEventListener('keyup', this.keyupHandler);
    document.body.removeEventListener('click', this.clickHandler);
  }

  keyupHandler(event) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  clickHandler(event) {
    if (this.config.closeModalOnClick && !this.modal.contains(event.target)) {
      this.closeModal();
    }
  }
}

export {Modals};
