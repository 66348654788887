import Flickity from 'flickity';

import Listeners from '../vendor/theme-scripts/listeners';
import {register} from '../vendor/theme-scripts/theme-sections';
import {videoPlay} from '../features/video-play';

const sections = {};

const selectors = {
  slideshow: '[data-mosaic-blocks]',
  slideshowPrev: '[data-prev-arrow]',
  slideshowNext: '[data-next-arrow]',
  dataSlide: 'data-slide',
  dataSlideIndex: 'data-slide-index',
};

const classes = {
  flickityEnabled: 'flickity-enabled',
};

class Mosaic {
  constructor(section) {
    this.container = section.container;
    this.slideshow = this.container.querySelector(selectors.slideshow);
    this.slideshowPrev = this.container.querySelector(selectors.slideshowPrev);
    this.slideshowNext = this.container.querySelector(selectors.slideshowNext);
    this.flkty = null;
    this._listeners = new Listeners();
    this.sliderInitEvent = () => this.initMobileSlider();

    this.init();
  }

  init() {
    this.initMobileSlider();

    document.addEventListener('theme:resize', this.sliderInitEvent);
  }

  initMobileSlider() {
    const isMobile = window.innerWidth < theme.sizes.small;

    const options = {
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
    };

    if (isMobile && !this.isInit()) {
      this.flkty = new Flickity(this.slideshow, options);

      // Bind slider controls event listeners
      if (this.slideshowPrev && this.slideshowNext) {
        this._listeners.add(this.slideshowPrev, 'click', () => this.flkty.previous(true));
        this._listeners.add(this.slideshowNext, 'click', () => this.flkty.next(true));
      }
    } else if (!isMobile && this.isInit()) {
      this.flkty.destroy();

      // Unbind all slider controls event listeners
      this._listeners.removeAll();
    }
  }

  isInit() {
    const isInitialized = this.slideshow?.classList.contains(classes.flickityEnabled);

    return isInitialized;
  }

  onUnload() {
    if (this.isInit()) {
      this.flkty.destroy();
    }

    document.removeEventListener('theme:resize', this.sliderInitEvent);
  }

  onBlockSelect(evt) {
    const slide = this.container.querySelector(`[${selectors.dataSlide}="${evt.detail.blockId}"]`);

    if (this.isInit() && slide !== null) {
      this.flkty.select(parseInt(slide.getAttribute(selectors.dataSlideIndex)));
    }
  }
}

const MosaicSection = {
  onLoad() {
    sections[this.id] = new Mosaic(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
  onBlockSelect(e) {
    sections[this.id].onBlockSelect(e);
  },
};

register('mosaic', [MosaicSection, videoPlay]);
