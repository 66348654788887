import {register} from '../vendor/theme-scripts/theme-sections';

const selectors = {
  inputGroups: '.input-group--error',
  inputs: 'input.password, input.email',
};

class Password {
  constructor(section) {
    this.container = section.container;
    this.inputGroups = this.container.querySelectorAll(selectors.inputGroups);

    this.init();
  }

  init() {
    this.inputGroups.forEach((inputGroup) => {
      const input = inputGroup.querySelector(selectors.inputs);
      input.focus();
    });
  }
}

const PasswordSection = {
  onLoad() {
    new Password(this);
  },
};

register('password', PasswordSection);
