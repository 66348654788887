import debounce from '../util/debounce';

const selectors = {
  frame: '[data-ticker-frame]',
  scale: '[data-ticker-scale]',
  text: '[data-ticker-text]',
  clone: 'data-clone',
  moveTime: 1.63, // 100px going to move for 1.63s
  space: 100, // 100px
};

const classes = {
  tickerAnimated: 'ticker--animated',
  tickerUnloaded: 'ticker--unloaded',
  tickerComparator: 'ticker__comparator',
};

const sections = {};

class Ticker {
  constructor(el, stopClone = false) {
    this.frame = el;
    this.stopClone = stopClone;
    this.scale = this.frame.querySelector(selectors.scale);
    this.text = this.frame.querySelector(selectors.text);

    this.comparator = this.text.cloneNode(true);
    this.comparator.classList.add(classes.tickerComparator);
    this.frame.appendChild(this.comparator);
    this.scale.classList.remove(classes.tickerUnloaded);
    this.checkWidthOnResize = debounce(() => this.checkWidth(), 100);
    this.listen();
  }

  listen() {
    document.addEventListener('theme:resize', this.checkWidthOnResize);
    this.checkWidth();
  }

  checkWidth() {
    const padding = window.getComputedStyle(this.frame).paddingLeft.replace('px', '') * 2;

    if (this.frame.clientWidth - padding < this.text.clientWidth || this.stopClone) {
      this.text.classList.add(classes.tickerAnimated);
      if (this.scale.childElementCount === 1) {
        this.clone = this.text.cloneNode(true);
        this.clone.setAttribute(selectors.clone, '');
        this.scale.appendChild(this.clone);

        if (this.stopClone) {
          for (let index = 0; index < 10; index++) {
            const cloneSecond = this.text.cloneNode(true);
            cloneSecond.setAttribute(selectors.clone, '');
            this.scale.appendChild(cloneSecond);
          }
        }

        const animationTimeFrame = (this.text.clientWidth / selectors.space) * selectors.moveTime;

        this.scale.style.setProperty('--animation-time', `${animationTimeFrame}s`);
      }
    } else {
      this.text.classList.add(classes.tickerAnimated);
      let clone = this.scale.querySelector(`[${selectors.clone}]`);
      if (clone) {
        this.scale.removeChild(clone);
      }
      this.text.classList.remove(classes.tickerAnimated);
    }
  }

  unload() {
    document.removeEventListener('theme:resize', this.checkWidthOnResize);
  }
}

const ticker = {
  onLoad() {
    sections[this.id] = [];
    const el = this.container.querySelectorAll(selectors.frame);
    el.forEach((el) => {
      sections[this.id].push(new Ticker(el));
    });
  },
  onUnload() {
    sections[this.id].forEach((el) => {
      if (typeof el.unload === 'function') {
        el.unload();
      }
    });
  },
};

export {ticker, Ticker};
