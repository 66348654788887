const selectors = {
  popoutWrapper: '[data-popout]',
  popoutList: '[data-popout-list]',
  popoutToggle: '[data-popout-toggle]',
  popoutInput: '[data-popout-input]',
  popoutOptions: '[data-popout-option]',
  popoutPrevent: 'data-popout-prevent',
  popoutQuantity: 'data-quantity-field',
  dataValue: 'data-value',
  dataName: 'data-name',
  ariaExpanded: 'aria-expanded',
  ariaCurrent: 'aria-current',
};

const classes = {
  listVisible: 'popout-list--visible',
  currentSuffix: '--current',
  classPopoutAlternative: 'popout-container--alt',
};

let sections = {};

class Popout {
  constructor(popout) {
    this.container = popout;
    this.popoutList = this.container.querySelector(selectors.popoutList);
    this.popoutToggle = this.container.querySelector(selectors.popoutToggle);
    this.popoutInput = this.container.querySelector(selectors.popoutInput);
    this.popoutOptions = this.container.querySelectorAll(selectors.popoutOptions);
    this.popoutPrevent = this.container.getAttribute(selectors.popoutPrevent) === 'true';

    this._connectOptions();
    this._connectToggle();
    this._onFocusOut();

    if (this.popoutInput && this.popoutInput.hasAttribute(selectors.popoutQuantity)) {
      document.addEventListener('theme:popout:update', this.updatePopout.bind(this));
    }
  }

  unload() {
    if (this.popoutOptions.length) {
      this.popoutOptions.forEach((element) => {
        element.removeEventListener('theme:popout:click', this.popupOptionsClick.bind(this));
        element.removeEventListener('click', this._connectOptionsDispatch.bind(this));
      });
    }

    this.popoutToggle.removeEventListener('click', this.popupToggleClick.bind(this));

    this.popoutToggle.removeEventListener('focusout', this.popupToggleFocusout.bind(this));

    this.popoutList.removeEventListener('focusout', this.popupListFocusout.bind(this));

    this.container.removeEventListener('keyup', this.containerKeyup.bind(this));
  }

  popupToggleClick(evt) {
    const ariaExpanded = evt.currentTarget.getAttribute(selectors.ariaExpanded) === 'true';
    evt.currentTarget.setAttribute(selectors.ariaExpanded, !ariaExpanded);
    this.popoutList.classList.toggle(classes.listVisible);
  }
  popupToggleFocusout(evt) {
    const popoutLostFocus = this.container.contains(evt.relatedTarget);

    if (!popoutLostFocus) {
      this._hideList();
    }
  }
  popupListFocusout(evt) {
    const childInFocus = evt.currentTarget.contains(evt.relatedTarget);
    const isVisible = this.popoutList.classList.contains(classes.listVisible);

    if (isVisible && !childInFocus) {
      this._hideList();
    }
  }
  popupOptionsClick(evt) {
    evt.preventDefault();
    let attrValue = '';
    if (evt.currentTarget.getAttribute(selectors.dataValue)) {
      attrValue = evt.currentTarget.getAttribute(selectors.dataValue);
    }
    this.popoutInput.value = attrValue;

    if (this.popoutPrevent) {
      attrValue = evt.currentTarget.getAttribute(selectors.dataName);
      this.popoutInput.dispatchEvent(new Event('change'));
      if (!evt.detail.preventTrigger && this.popoutInput.hasAttribute(selectors.popoutQuantity)) {
        this.popoutInput.dispatchEvent(new Event('input'));
      }
      const currentElement = this.popoutList.querySelector(`[class*="${classes.currentSuffix}"]`);
      let targetClass = classes.currentSuffix;
      if (currentElement && currentElement.classList.length) {
        for (const currentElementClass of currentElement.classList) {
          if (currentElementClass.includes(classes.currentSuffix)) {
            targetClass = currentElementClass;
            break;
          }
        }
      }

      const listTargetElement = this.popoutList.querySelector(`.${targetClass}`);
      if (listTargetElement) {
        listTargetElement.classList.remove(`${targetClass}`);
        evt.currentTarget.parentElement.classList.add(`${targetClass}`);
      }

      const targetAttribute = this.popoutList.querySelector(`[${selectors.ariaCurrent}]`);
      if (targetAttribute && targetAttribute.hasAttribute(`${selectors.ariaCurrent}`)) {
        targetAttribute.removeAttribute(`${selectors.ariaCurrent}`);
        evt.currentTarget.setAttribute(`${selectors.ariaCurrent}`, 'true');
      }

      if (attrValue !== '') {
        this.popoutToggle.textContent = attrValue;
      }

      this.popupToggleFocusout(evt);
      this.popupListFocusout(evt);
    } else {
      this._submitForm(attrValue);
    }
  }
  updatePopout(evt) {
    const targetElement = this.popoutList.querySelector(`[${selectors.dataValue}="${this.popoutInput.value}"]`);
    if (targetElement) {
      targetElement.dispatchEvent(
        new CustomEvent('theme:popout:click', {
          cancelable: true,
          bubbles: true,
          detail: {
            preventTrigger: true,
          },
        })
      );

      if (!targetElement.parentElement.nextSibling) {
        this.container.classList.add(classes.classPopoutAlternative);
      }
    } else {
      this.container.classList.add(classes.classPopoutAlternative);
    }
  }

  containerKeyup(evt) {
    if (evt.which !== theme.keyboardKeys.ESCAPE) {
      return;
    }
    this._hideList();
    this.popoutToggle.focus();
  }

  bodyClick(evt) {
    const isOption = this.container.contains(evt.target);
    const isVisible = this.popoutList.classList.contains(classes.listVisible);

    if (isVisible && !isOption) {
      this._hideList();
    }
  }

  _connectToggle() {
    this.popoutToggle.addEventListener('click', this.popupToggleClick.bind(this));
  }

  _connectOptions() {
    if (this.popoutOptions.length) {
      this.popoutOptions.forEach((element) => {
        element.addEventListener('theme:popout:click', this.popupOptionsClick.bind(this));
        element.addEventListener('click', this._connectOptionsDispatch.bind(this));
      });
    }
  }

  _connectOptionsDispatch(evt) {
    const event = new CustomEvent('theme:popout:click', {
      cancelable: true,
      bubbles: true,
      detail: {
        preventTrigger: false,
      },
    });

    if (!evt.target.dispatchEvent(event)) {
      evt.preventDefault();
    }
  }

  _onFocusOut() {
    this.popoutToggle.addEventListener('focusout', this.popupToggleFocusout.bind(this));

    this.popoutList.addEventListener('focusout', this.popupListFocusout.bind(this));

    this.container.addEventListener('keyup', this.containerKeyup.bind(this));

    document.body.addEventListener('click', this.bodyClick.bind(this));
  }

  _submitForm(value) {
    const form = this.container.closest('form');
    if (form) {
      form.submit();
    }
  }

  _hideList() {
    this.popoutList.classList.remove(classes.listVisible);
    this.popoutToggle.setAttribute(selectors.ariaExpanded, false);
  }
}

const popoutSection = {
  onLoad() {
    sections[this.id] = [];
    const wrappers = this.container.querySelectorAll(selectors.popoutWrapper);
    wrappers.forEach((wrapper) => {
      sections[this.id].push(new Popout(wrapper));
    });
  },
  onUnload() {
    sections[this.id].forEach((popout) => {
      if (typeof popout.unload === 'function') {
        popout.unload();
      }
    });
  },
};

export {Popout, popoutSection};
