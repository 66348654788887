import loadScript from '../util/loader';
import * as a11y from '../vendor/theme-scripts/theme-a11y';

import {LoadVideoVimeo} from './load-video-vimeo';
import {LoadVideoYT} from './load-video-youtube';

const selectors = {
  popupContainer: '.pswp',
  popupCloseBtn: '.pswp__custom-close',
  popupIframe: 'iframe, video',
  popupCustomIframe: '.pswp__custom-iframe',
  popupThumbs: '.pswp__thumbs',
  dataOptionClasses: 'data-pswp-option-classes',
  dataVideoType: 'data-video-type',
};

const classes = {
  classCurrent: 'is-current',
  classCustomLoader: 'pswp--custom-loader',
  classCustomOpen: 'pswp--custom-opening',
  classLoader: 'pswp__loader',
};

const loaderHTML = `<div class="${classes.classLoader}"><div class="loader pswp__loader-line"><div class="loader-indeterminate"></div></div></div>`;

class LoadPhotoswipe {
  constructor(items, options = '') {
    this.accessibility = a11y;
    this.items = items;
    this.pswpElement = document.querySelectorAll(selectors.popupContainer)[0];
    this.popup = null;
    this.popupThumbs = null;
    this.popupThumbsContainer = this.pswpElement.querySelector(selectors.popupThumbs);
    this.closeBtn = this.pswpElement.querySelector(selectors.popupCloseBtn);
    this.closeButtonClick = () => this.closeButtonClickEvent();
    const defaultOptions = {
      history: false,
      focus: false,
      mainClass: '',
    };
    this.options = options !== '' ? options : defaultOptions;

    this.init();
  }

  init() {
    this.pswpElement.classList.add(classes.classCustomOpen);

    this.initLoader();

    loadScript({url: window.theme.assets.photoswipe})
      .then(() => this.loadPopup())
      .catch((e) => console.error(e));
  }

  initLoader() {
    if (this.pswpElement.classList.contains(classes.classCustomLoader) && this.options !== '' && this.options.mainClass) {
      this.pswpElement.setAttribute(selectors.dataOptionClasses, this.options.mainClass);
      let loaderElem = document.createElement('div');
      loaderElem.innerHTML = loaderHTML;
      loaderElem = loaderElem.firstChild;
      this.pswpElement.appendChild(loaderElem);
    } else {
      this.pswpElement.setAttribute(selectors.dataOptionClasses, '');
    }
  }

  loadPopup() {
    const PhotoSwipe = window.themePhotoswipe.PhotoSwipe.default;
    const PhotoSwipeUI = window.themePhotoswipe.PhotoSwipeUI.default;

    if (this.pswpElement.classList.contains(classes.classCustomLoader)) {
      this.pswpElement.classList.remove(classes.classCustomLoader);
    }

    this.pswpElement.classList.remove(classes.classCustomOpen);

    this.popup = new PhotoSwipe(this.pswpElement, PhotoSwipeUI, this.items, this.options);
    this.popup.init();

    this.accessibility.trapFocus(this.pswpElement);

    this.initVideo();

    this.thumbsActions();

    if (this.closeBtn) {
      this.closeBtn.addEventListener('click', this.closeButtonClick);
    }

    this.popup.listen('close', () => this.onClose());
  }

  closeButtonClickEvent() {
    this.popup.close();
    this.accessibility.removeTrapFocus();
    if (theme.a11yTrigger !== null) {
      theme.a11yTrigger.focus();
    }
  }

  initVideo() {
    const videoContainer = this.pswpElement.querySelector(selectors.popupCustomIframe);
    if (videoContainer) {
      const videoType = videoContainer.getAttribute(selectors.dataVideoType);

      if (videoType == 'youtube') {
        new LoadVideoYT(videoContainer.parentElement);
      } else if (videoType == 'vimeo') {
        new LoadVideoVimeo(videoContainer.parentElement);
      }
    }
  }

  thumbsActions() {
    if (this.popupThumbsContainer && this.popupThumbsContainer.firstChild) {
      this.popupThumbsContainer.addEventListener('wheel', (e) => this.stopDisabledScroll(e));
      this.popupThumbsContainer.addEventListener('mousewheel', (e) => this.stopDisabledScroll(e));
      this.popupThumbsContainer.addEventListener('DOMMouseScroll', (e) => this.stopDisabledScroll(e));

      this.popupThumbs = this.pswpElement.querySelectorAll(`${selectors.popupThumbs} > *`);
      this.popupThumbs.forEach((element, i) => {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          element.parentElement.querySelector(`.${classes.classCurrent}`).classList.remove(classes.classCurrent);
          element.classList.add(classes.classCurrent);
          this.popup.goTo(i);
        });
      });

      this.popup.listen('imageLoadComplete', () => this.setCurrentThumb());
      this.popup.listen('beforeChange', () => this.setCurrentThumb());
    }
  }

  stopDisabledScroll(e) {
    e.stopPropagation();
  }

  onClose() {
    const popupIframe = this.pswpElement.querySelector(selectors.popupIframe);
    if (popupIframe) {
      popupIframe.parentNode.removeChild(popupIframe);
    }

    if (this.popupThumbsContainer && this.popupThumbsContainer.firstChild) {
      while (this.popupThumbsContainer.firstChild) {
        this.popupThumbsContainer.removeChild(this.popupThumbsContainer.firstChild);
      }
    }

    this.pswpElement.setAttribute(selectors.dataOptionClasses, '');
    const loaderElem = this.pswpElement.querySelector(`.${classes.classLoader}`);
    if (loaderElem) {
      this.pswpElement.removeChild(loaderElem);
    }

    this.accessibility.removeTrapFocus();

    if (theme.a11yTrigger !== null) {
      theme.a11yTrigger.focus();
    }

    this.closeBtn.removeEventListener('click', this.closeButtonClick);
  }

  setCurrentThumb() {
    const lastCurrentThumb = this.pswpElement.querySelector(`${selectors.popupThumbs} > .${classes.classCurrent}`);
    if (lastCurrentThumb) {
      lastCurrentThumb.classList.remove(classes.classCurrent);
    }

    if (!this.popupThumbs) {
      return;
    }
    const currentThumb = this.popupThumbs[this.popup.getCurrentIndex()];
    currentThumb.classList.add(classes.classCurrent);
    this.scrollThumbs(currentThumb);
  }

  scrollThumbs(currentThumb) {
    const thumbsContainerLeft = this.popupThumbsContainer.scrollLeft;
    const thumbsContainerWidth = this.popupThumbsContainer.offsetWidth;
    const thumbsContainerPos = thumbsContainerLeft + thumbsContainerWidth;
    const currentThumbLeft = currentThumb.offsetLeft;
    const currentThumbWidth = currentThumb.offsetWidth;
    const currentThumbPos = currentThumbLeft + currentThumbWidth;

    if (thumbsContainerPos <= currentThumbPos || thumbsContainerPos > currentThumbLeft) {
      const currentThumbMarginLeft = parseInt(window.getComputedStyle(currentThumb).marginLeft);
      this.popupThumbsContainer.scrollTo({
        top: 0,
        left: currentThumbLeft - currentThumbMarginLeft,
        behavior: 'smooth',
      });
    }
  }
}

export {LoadPhotoswipe};
