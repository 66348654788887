window.theme = window.theme || {};

window.theme.subscribers = {};

window.theme.sizes = {
  mobile: 480,
  small: 768,
  large: 1024,
  widescreen: 1320,
};

window.theme.keyboardKeys = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  LEFTARROW: 37,
  RIGHTARROW: 39,
};

window.theme.dimensions = {
  headerScrolled: 60,
};

window.theme.focusable = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
window.theme.a11yTrigger = null;
