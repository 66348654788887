(function () {
  function n(n) {
    var i = window.innerWidth || document.documentElement.clientWidth,
      r = window.innerHeight || document.documentElement.clientHeight,
      t = n.getBoundingClientRect();
    return t.top >= 0 && t.bottom <= r && t.left >= 0 && t.right <= i;
  }
  function t(n) {
    var i = window.innerWidth || document.documentElement.clientWidth,
      r = window.innerHeight || document.documentElement.clientHeight,
      t = n.getBoundingClientRect(),
      u = (t.left >= 0 && t.left <= i) || (t.right >= 0 && t.right <= i),
      f = (t.top >= 0 && t.top <= r) || (t.bottom >= 0 && t.bottom <= r);
    return u && f;
  }
  function i(n, i) {
    function r() {
      var r = t(n);
      r != u && ((u = r), typeof i == 'function' && i(r, n));
    }
    var u = t(n);
    window.addEventListener('load', r);
    window.addEventListener('resize', r);
    window.addEventListener('scroll', r);
  }
  function r(t, i) {
    function r() {
      var r = n(t);
      r != u && ((u = r), typeof i == 'function' && i(r, t));
    }
    var u = n(t);
    window.addEventListener('load', r);
    window.addEventListener('resize', r);
    window.addEventListener('scroll', r);
  }
  window.visibilityHelper = {isElementTotallyVisible: n, isElementPartiallyVisible: t, inViewportPartially: i, inViewportTotally: r};
})();
