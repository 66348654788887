import {register} from '../vendor/theme-scripts/theme-sections';
import {newsletterCheckForResultSection} from '../globals/newsletter';
import {PopupCookie} from '../globals/popup-cookie';

const selectors = {
  popup: '[data-popup]',
  close: '[data-popup-close]',
};

const classes = {
  popupVisible: 'popup--visible',
};

const attributes = {
  delay: 'data-delay',
  reappearTime: 'data-reappear_time',
  testmode: 'data-testmode',
  checkTrueString: 'true',
};

let sections = {};

class Popup {
  constructor(el) {
    this.popup = el;
    this.close = this.popup.querySelector(selectors.close);
    this.timeout = 0;
    this.testmode = this.popup.getAttribute(attributes.testmode) === attributes.checkTrueString;
    this.delay = parseInt(this.popup.getAttribute(attributes.delay)) * 1000;
    this.cookie = new PopupCookie('newsletter', 'user_has_closed', this.expireDate());

    this.init();
  }

  expireDate() {
    const todayDate = new Date();
    const expireDate = new Date();
    let date = parseInt(this.popup.getAttribute(attributes.reappearTime));

    if (date !== 0) {
      expireDate.setTime(todayDate.getTime() + 3600000 * 24 * date);
    } else {
      expireDate.setTime(todayDate.getTime() + 3600000 * 24 * 365 * 100);
    }

    date = expireDate.toGMTString();

    return date;
  }

  init() {
    const cookieExists = this.cookie.read() !== false;
    const isChallengePage = window.location.pathname === '/challenge';
    const submissionSuccess = window.location.search.indexOf('?customer_posted=true') !== -1;

    if (submissionSuccess) {
      this.delay = 0;
    }

    if ((!cookieExists && !isChallengePage) || this.testmode) {
      this.timeout = setTimeout(() => {
        this.popup.classList.add(classes.popupVisible);
        this.initClosers();
        this.close.focus();
      }, this.delay);
    }
  }

  initClosers() {
    this.close.addEventListener('click', this.closeModal.bind(this));
  }

  closeModal(e) {
    e.preventDefault();
    this.popup.classList.remove(classes.popupVisible);
    clearTimeout(this.timeout);

    if (!this.testmode) {
      this.cookie.write();
    }
  }
}

const popupSection = {
  onLoad() {
    const popup = this.container.querySelector(selectors.popup);
    if (popup !== null) {
      sections[this.id] = new Popup(popup);
    }
  },
};

register('popup', [popupSection, newsletterCheckForResultSection]);
