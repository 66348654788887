import {fetchProduct} from '../util/fetch-product';
import handle from '../util/handle';
import loadScript from '../util/loader';

const defaults = {
  color: 'ash',
};

const selectors = {
  swatch: 'data-swatch',
  swatchColor: '[data-swatch-color]',
  wrapper: '[data-grid-swatches]',
  template: '[data-swatch-template]',
  button: '[data-swatch-button]',
  link: '[data-swatch-link]',
  handle: 'data-swatch-handle',
  label: 'data-swatch-label',
};

class ColorMatch {
  constructor(options = {}) {
    this.settings = {
      ...defaults,
      ...options,
    };

    this.match = this.init();
  }

  getColor() {
    return this.match;
  }

  init() {
    const getColors = loadScript({json: theme.assets.swatches});
    return getColors
      .then((colors) => {
        return this.matchColors(colors, this.settings.color);
      })
      .catch((e) => {
        console.log('failed to load swatch colors script');
        console.log(e);
      });
  }

  matchColors(colors, name) {
    let bg = '#E5E5E5';
    let img = null;
    const path = theme.assets.base || '/';
    const comparisonName = name.toLowerCase().replace(/\s/g, '');
    const array = colors.colors;

    if (array) {
      let indexArray = null;

      const hexColorArr = array.filter((colorObj, index) => {
        const neatName = Object.keys(colorObj).toString().toLowerCase().replace(/\s/g, '');

        if (neatName === comparisonName) {
          indexArray = index;

          return colorObj;
        }
      });

      if (hexColorArr.length && indexArray !== null) {
        const value = Object.values(array[indexArray])[0];
        bg = value;

        if (value.includes('.jpg') || value.includes('.jpeg') || value.includes('.png') || value.includes('.svg')) {
          img = `${path}${value}`;
          bg = '#888888';
        }
      }
    }

    return {
      color: this.settings.color,
      path: img,
      hex: bg,
    };
  }
}

class Swatch {
  constructor(element) {
    this.element = element;
    this.swatchColor = this.element.querySelector(selectors.swatchColor);
    this.colorString = element.getAttribute(selectors.swatch);
    const matcher = new ColorMatch({color: this.colorString});
    matcher.getColor().then((result) => {
      this.colorMatch = result;
      this.init();
    });
  }

  init() {
    if (this.colorMatch && this.colorMatch.hex) {
      this.swatchColor.style.setProperty('--swatch', `${this.colorMatch.hex}`);
    }
    if (this.colorMatch && this.colorMatch.path) {
      this.swatchColor.style.setProperty('background-image', `url(${this.colorMatch.path})`);
    }
  }
}

class GridSwatch {
  constructor(wrap, container) {
    this.template = document.querySelector(selectors.template).innerHTML;
    this.wrap = wrap;
    this.container = container;
    this.handle = wrap.getAttribute(selectors.handle);
    const label = wrap.getAttribute(selectors.label).trim().toLowerCase();
    fetchProduct(this.handle).then((product) => {
      this.product = product;
      this.colorOption = product.options.find(function (element) {
        return element.name.toLowerCase() === label || null;
      });

      if (this.colorOption) {
        this.swatches = this.colorOption.values;
        this.init();
      }
    });
  }

  init() {
    this.wrap.innerHTML = '';

    this.swatches.forEach((swatch) => {
      let variant = this.product.variants.find((variant) => {
        return variant.options.includes(swatch);
      });

      if (variant) {
        const swatchTemplate = document.createElement('div');
        swatchTemplate.innerHTML = this.template;
        const button = swatchTemplate.querySelector(selectors.button);
        const swatchElement = swatchTemplate.querySelector(`[${selectors.swatch}]`);
        const link = swatchTemplate.querySelector(selectors.link);
        const swatchColor = swatchTemplate.querySelector(selectors.swatchColor);

        button.dataset.value = swatch;
        swatchElement.dataset.swatch = swatch;
        swatchElement.dataset.swatchVariant = variant.id;
        link.href = `${this.product.url}?variant=${variant.id}`;
        link.textContent = swatch;
        swatchColor.classList.add(`${swatchColor.classList[0]}--${handle(swatch)}`);

        this.wrap.innerHTML += swatchTemplate.innerHTML;
      }
    });

    this.swatchElements = this.wrap.querySelectorAll(`[${selectors.swatch}]`);

    this.swatchElements.forEach((el) => {
      new Swatch(el);
    });
  }
}

const makeGridSwatches = (container) => {
  const gridSwatchWrappers = container.querySelectorAll(selectors.wrapper);
  gridSwatchWrappers.forEach((wrap) => {
    new GridSwatch(wrap, this);
  });
};

const swatchSection = {
  onLoad() {
    this.swatches = [];
    const els = this.container.querySelectorAll(`[${selectors.swatch}]`);
    els.forEach((el) => {
      this.swatches.push(new Swatch(el));
    });
  },
};

const swatchGridSection = {
  onLoad() {
    makeGridSwatches(this.container);
  },
};

export {swatchGridSection, swatchSection, makeGridSwatches, Swatch};
