function subscribe(eventName, callback) {
  if (window.theme.subscribers[eventName] === undefined) {
    window.theme.subscribers[eventName] = [];
  }

  window.theme.subscribers[eventName] = [...window.theme.subscribers[eventName], callback];

  return function unsubscribe() {
    window.theme.subscribers[eventName] = window.theme.subscribers[eventName].filter((cb) => {
      return cb !== callback;
    });
  };
}

function publish(eventName, data) {
  if (window.theme.subscribers[eventName]) {
    window.theme.subscribers[eventName].forEach((callback) => {
      callback(data);
    });
  }
}

export {subscribe, publish};
