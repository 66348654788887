import {Ticker} from '../features/ticker';
import {Slider} from '../features/slider';

const selectors = {
  bar: '[data-bar]',
  barSlide: '[data-slide]',
  frame: '[data-ticker-frame]',
  header: '[data-header-wrapper]',
  slider: '[data-slider]',
  slideValue: 'data-slide',
  tickerScale: '[data-ticker-scale]',
  tickerText: '[data-ticker-text]',
};

const attributes = {
  dataStop: 'data-stop',
  style: 'style',
  dataTargetReferrer: 'data-target-referrer',
};

const classes = {
  tickerAnimated: 'ticker--animated',
  mobile: 'mobile',
  desktop: 'desktop',
};

class AnnouncementBar extends HTMLElement {
  constructor() {
    super();
    this.locationPath = location.href;

    this.slides = this.querySelectorAll(selectors.barSlide);
    this.slider = this.querySelector(selectors.slider);
    this.initSliderFlag = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= theme.sizes.small ? true : false;
    this.initSliderEvent = (event) => this.initSlider(event);
  }

  connectedCallback() {
    this.removeAnnouncement();

    if (this.slider) {
      this.initSliders();
    }

    if (!this.slider) {
      this.initTickers(true);
    }

    this.addEventListener('theme:block:select', (e) => {
      this.onBlockSelect(e);
    });

    this.addEventListener('theme:block:deselect', (e) => {
      this.onBlockDeselect(e);
    });
  }

  /**
   * Delete announcement which has a target referrer attribute and it is not contained in page URL
   */
  removeAnnouncement() {
    for (let index = 0; index < this.slides.length; index++) {
      const element = this.slides[index];

      if (!element.hasAttribute(attributes.dataTargetReferrer)) {
        continue;
      }

      if (this.locationPath.indexOf(element.getAttribute(attributes.dataTargetReferrer)) === -1 && !window.Shopify.designMode) {
        element.parentNode.removeChild(element);
      }
    }
  }

  /**
   * Init slider
   */
  initSliders() {
    this.initSlider();
    document.addEventListener('theme:resize:width', this.initSliderEvent);

    this.addEventListener('theme:slider:loaded', () => this.initTickers());
  }

  initSlider(event) {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if ((windowWidth >= theme.sizes.small && this.initSliderFlag) || (windowWidth < theme.sizes.small && !this.initSliderFlag)) {
      let slidesSelector = '';

      if (this.slider.flkty) {
        this.slider.flkty.destroy();
      }

      if (windowWidth >= theme.sizes.small && this.initSliderFlag) {
        this.initSliderFlag = false;
        slidesSelector = `${selectors.barSlide}:not(.${classes.mobile})`;
      } else if (windowWidth < theme.sizes.small && !this.initSliderFlag) {
        this.initSliderFlag = true;
        slidesSelector = `${selectors.barSlide}:not(.${classes.desktop})`;
      }

      if (this.querySelector(slidesSelector)) {
        this.slider = new Slider(this, this.querySelector(selectors.slider), slidesSelector);
        this.slider.flkty.reposition();
      }
    }
  }

  /**
   * Init tickers in sliders
   */
  initTickers(stopClone = false) {
    const frames = this.querySelectorAll(selectors.frame);

    frames.forEach((element) => {
      new Ticker(element, stopClone);
    });
  }

  toggleTicker(e, isStoped = false) {
    const tickerScale = document.querySelector(selectors.tickerScale);
    const element = document.querySelector(`[${selectors.slideValue}="${e.detail.blockId}"]`);

    if (isStoped && element) {
      tickerScale.setAttribute(attributes.dataStop, '');
      tickerScale.querySelectorAll(selectors.tickerText).forEach((textHolder) => {
        textHolder.classList.remove(classes.tickerAnimated);
        textHolder.style.transform = `translate3d(${-(element.offsetLeft - element.clientWidth)}px, 0, 0)`;
      });
    }

    if (!isStoped && element) {
      tickerScale.querySelectorAll(selectors.tickerText).forEach((textHolder) => {
        textHolder.classList.add(classes.tickerAnimated);
        textHolder.removeAttribute(attributes.style);
      });
      tickerScale.removeAttribute(attributes.dataStop);
    }
  }

  onBlockSelect(e) {
    if (this.slider) {
      this.slider.onBlockSelect(e);
    } else {
      this.toggleTicker(e, true);
    }
  }

  onBlockDeselect(e) {
    if (this.slider) {
      this.slider.onBlockDeselect(e);
    } else {
      this.toggleTicker(e, false);
    }
  }

  disconnectedCallback() {
    document.removeEventListener('theme:resize:width', this.initSliderEvent);
  }
}

if (!customElements.get('announcement-bar')) {
  customElements.define('announcement-bar', AnnouncementBar);
}
