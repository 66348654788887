import * as a11y from '../vendor/theme-scripts/theme-a11y';

const settings = {
  elements: {
    html: 'html',
    body: 'body',
    inPageLink: '[data-skip-content]',
    linksWithOnlyHash: 'a[href="#"]',
    triggerFocusElement: '[data-focus-element]',
  },
  classes: {
    focus: 'is-focused',
  },
};

class Accessibility {
  constructor() {
    this.init();
  }

  init() {
    this.settings = settings;
    this.window = window;
    this.document = document;
    this.a11y = a11y;

    // DOM Elements
    this.inPageLink = this.document.querySelector(this.settings.elements.inPageLink);
    this.linksWithOnlyHash = this.document.querySelectorAll(this.settings.elements.linksWithOnlyHash);
    this.html = this.document.querySelector(this.settings.elements.html);
    this.body = this.document.querySelector(this.settings.elements.body);
    this.lastFocused = null;

    // Flags
    this.isFocused = false;

    // A11Y init methods
    this.a11y.focusHash();
    this.a11y.bindInPageLinks();

    // Events
    this.clickEvents();
    this.focusEvents();
    this.focusEventsOff();
    this.closeExpandedElements();
  }

  /**
   * Clicked events accessibility
   *
   * @return  {Void}
   */

  clickEvents() {
    if (this.inPageLink) {
      this.inPageLink.addEventListener('click', (event) => {
        event.preventDefault();
      });
    }

    if (this.linksWithOnlyHash) {
      this.linksWithOnlyHash.forEach((item) => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
        });
      });
    }
  }

  /**
   * Focus events
   *
   * @return  {Void}
   */

  focusEvents() {
    this.document.addEventListener('keyup', (event) => {
      if (event.keyCode !== theme.keyboardKeys.TAB) {
        return;
      }

      this.body.classList.add(this.settings.classes.focus);
      this.isFocused = true;
    });

    // Expand modals
    this.document.addEventListener('keyup', (event) => {
      if (!this.isFocused) {
        return;
      }

      const target = event.target;
      const pressEnterOrSpace = event.keyCode === theme.keyboardKeys.ENTER || event.keyCode === theme.keyboardKeys.SPACE;
      const targetElement = target.matches(this.settings.elements.triggerFocusElement) || target.closest(this.settings.elements.triggerFocusElement);

      if (pressEnterOrSpace && targetElement) {
        if (this.lastFocused === null) {
          this.lastFocused = target;
        }
      }
    });

    // Focus addToCart button or quickview button
    this.html.addEventListener('theme:cart:add', (event) => {
      this.lastFocused = event.detail.selector;
    });
  }

  /**
   * Focus events off
   *
   * @return  {Void}
   */

  focusEventsOff() {
    this.document.addEventListener('mousedown', () => {
      this.body.classList.remove(this.settings.classes.focus);
      this.isFocused = false;
    });
  }

  /**
   * Close expanded elements with when press escape
   *
   * @return  {Void}
   */

  closeExpandedElements() {
    document.addEventListener('keyup', (event) => {
      if (event.keyCode !== theme.keyboardKeys.ESCAPE) {
        return;
      }

      this.a11y.removeTrapFocus();

      if (this.lastFocused !== null) {
        setTimeout(() => {
          this.lastFocused.focus();
          this.lastFocused = null;
        }, 600);
      }
    });
  }
}

window.accessibility = new Accessibility();
