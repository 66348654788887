import {formatMoney} from '@shopify/theme-currency';
import FlickityAsNavFor from 'flickity-as-nav-for';

import QuantityCounter from '../globals/quantity-handle';
import {StoreAvailability} from './store-availability';
import {ProductForm} from '../vendor/theme-scripts/theme-product-form';
import {publish} from '../util/pubsub';

const events = {
  variantChange: 'variant-change',
};

const selectors = {
  addToCart: '[data-add-to-cart]',
  addToCartText: '[data-add-to-cart-text]',
  colorLabel: '[data-color-label]',
  comparePrice: '[data-compare-price]',
  dataImageId: 'data-id',
  dataOption: '[data-option]',
  idInput: '[name="id"]',
  notificationForm: '[data-notification-form]',
  originalSelectorId: '[data-product-select]',
  preOrderTag: '_preorder',
  priceWrapper: '[data-price-wrapper]',
  product: '[data-product-container]',
  productForm: '[data-product-form-container]',
  productImage: '[data-product-single-media-wrapper]',
  productImagesScroller: '[data-custom-scrollbar-items]',
  productJson: '[data-product-json]',
  productPrice: '[data-product-price]',
  productSlideshow: '[data-product-slideshow]',
  remainingCount: '[data-remaining-count]',
  remainingMax: '[data-remaining-max]',
  remainingWrapper: '[data-remaining-wrapper]',
  remainingJSON: '[data-product-remaining-json]',
  shopBarImageActive: '[data-shop-bar-image-active]',
  showQuantity: '[data-show-quantity]',
  storeAvailabilityContainer: '[data-store-availability-container]',
  unitBase: '[data-product-base]',
  unitPrice: '[data-product-unit-price]',
  unitWrapper: '[data-product-unit]',
  variantId: '[data-variant-id]',
  variantIdAttr: 'data-variant-id',
  dataSectionId: 'data-section-id',

};

const classes = {
  hidden: 'hidden',
  hiddenPrice: 'product__price--hidden',
  onboarding: 'onboarding-product',
  productPriceSale: 'product__price--sale',
  remainingIn: 'count-is-in',
  remainingLow: 'count-is-low',
  remainingOut: 'count-is-out',
  remainingUnavailable: 'count-is-unavailable',
  scrollable: 'product--layout-scrollable',
  shopBarImageShown: 'shop-bar__image--shown',
  sliderEnabled: 'flickity-enabled',
  visuallyHidden: 'visually-hidden',
};

const attributes = {
  remainingMax: 'data-remaining-max',
  shopBarImageActive: 'data-shop-bar-image-active',
  variantId: 'data-variant-id',
};

class ProductAddForm {
  constructor(section) {
    this.section = section;
    this.container = section.container;
    this.sectionId = this.container.getAttribute(selectors.dataSectionId);
    this.product = this.container.querySelector(selectors.product);
    this.onboarding = this.product.classList.contains(classes.onboarding);
    this.scrollable = this.product.classList.contains(classes.scrollable);
    this.storeAvailabilityContainer = this.container.querySelector(selectors.storeAvailabilityContainer);

    // Stop parsing if we don't have the product
    if (!this.product || this.onboarding) {
      return;
    }

    this.productForm = this.container.querySelector(selectors.productForm);

    this.remainingWrapper = this.container.querySelector(selectors.remainingWrapper);

    if (this.remainingWrapper) {
      const remainingMaxWrap = this.container.querySelector(selectors.remainingMax);
      if (remainingMaxWrap) {
        this.remainingMaxInt = parseInt(remainingMaxWrap.getAttribute(attributes.remainingMax), 10);
        this.remainingCount = this.container.querySelector(selectors.remainingCount);
        this.remainingJSONWrapper = this.container.querySelector(selectors.remainingJSON);
        this.remainingJSON = null;

        if (this.remainingJSONWrapper && this.remainingJSONWrapper.innerHTML !== '') {
          this.remainingJSON = JSON.parse(this.remainingJSONWrapper.innerHTML);
        } else {
          console.warn('Missing product quantity JSON');
        }
      }
    }

    if (this.product.querySelector(selectors.showQuantity)) {
      const counter = new QuantityCounter(this.container);
      counter.init();
    }

    this.init();

    this.hasUnitPricing = this.container.querySelector(selectors.unitWrapper);
  }

  init() {
    let productJSON = null;
    const productElemJSON = this.container.querySelector(selectors.productJson);
    if (productElemJSON) {
      productJSON = productElemJSON.innerHTML;
    }
    if (productJSON) {
      this.productJSON = JSON.parse(productJSON);
      this.linkForm();
    } else {
      console.error('Missing product JSON');
    }

    if (this.storeAvailabilityContainer) {
      this.storeAvailability = new StoreAvailability(this.storeAvailabilityContainer);
      let variantId = this.productForm.product.variants[0].id;

      if (this.productForm.product.variants.length > 1) {
        // If there are more variants - set current variant id
        variantId = this.productForm.variant().id;
      }
      this.storeAvailability.updateContent(variantId, this.productForm.product.title);
    }
  }

  destroy() {
    this.productForm.destroy();
  }

  linkForm() {
    this.productForm = new ProductForm(this.productForm, this.productJSON, {
      onOptionChange: this.onOptionChange.bind(this),
    });

    const variants = this.productForm.product.variants;
    const variant = variants.length > 1 ? this.productForm._getProductFormEventData().variant : variants[0];

    this.fireHookEvent(variant);
    
    if (variants.length > 1) {
      this.updateRemaining(this.productForm._getProductFormEventData());
    }

  }

  onOptionChange(evt) {
    this.updateAddToCartState(evt);
    this.updateProductImage(evt);
    this.updateProductPrices(evt);
    this.updateColorName(evt);
    this.updateHiddenSelect(evt);
    this.updateShopBarImage(evt);
    this.updateRemaining(evt);
    this.fireHookEvent(evt.dataset.variant);

    if (this.storeAvailability) {
      this.updateStoreAvailability(evt);
    }
  }

  updateHiddenSelect(evt) {
    const variant = evt.dataset.variant;

    if (variant) {
      let idInputElement = this.container.querySelector(selectors.idInput);
      if (!idInputElement) {
        idInputElement = document.createElement('input');
        idInputElement.type = 'hidden';
        idInputElement.name = 'id';
        this.element.appendChild(idInputElement);
      }
      idInputElement.value = variant.id.toString();
      idInputElement.dispatchEvent(new Event('change', {bubbles: true}));
    }
  }

  updateAddToCartState(evt) {
    const variant = evt.dataset.variant;
    const notificationForm = this.container.querySelectorAll(selectors.notificationForm);
    const addToCart = this.container.querySelectorAll(selectors.addToCart);
    const addToCartText = this.container.querySelectorAll(selectors.addToCartText);
    const formSelects = this.container.querySelectorAll(selectors.originalSelectorId);
    let addText = theme.translations.add_to_cart;

    if (this.productJSON.tags.includes(selectors.preOrderTag)) {
      addText = theme.translations.pre_order;
    }

    if (notificationForm.length) {
      notificationForm.forEach((element) => {
        if (variant) {
          if (variant.available) {
            element.classList.add(classes.hidden);
          } else {
            element.classList.remove(classes.hidden);
          }
        } else {
          element.classList.remove(classes.hidden);
        }
      });
    }

    if (addToCart.length) {
      addToCart.forEach((element) => {
        if (variant) {
          if (variant.available) {
            element.disabled = false;
          } else {
            element.disabled = true;
          }
        } else {
          element.disabled = true;
        }
      });
    }

    if (addToCartText.length) {
      addToCartText.forEach((element) => {
        if (variant) {
          if (variant.available) {
            element.innerHTML = addText;
          } else {
            element.innerHTML = theme.translations.sold_out;
          }
        } else {
          element.innerHTML = theme.translations.unavailable;
        }
      });
    }

    if (formSelects && variant) {
      formSelects.forEach((formSelect) => {
        formSelect.value = variant.id;
      });
    }
  }

  updateStoreAvailability(evt) {
    if (evt.dataset.variant) {
      this.storeAvailability.updateContent(evt.dataset.variant.id, evt.dataset.variant.title);
    } else {
      this.storeAvailability.clearContent();
    }
  }

  updateRemaining(evt) {
    const variant = evt?.variant || evt.dataset.variant;

    this.remainingWrapper?.classList.remove(classes.remainingIn, classes.remainingOut, classes.remainingUnavailable, classes.remainingLow);

    if (variant && this.remainingWrapper && this.remainingJSON) {
      const remaining = this.remainingJSON[variant.id];

      if (remaining === 'out' || remaining < 1) {
        this.remainingWrapper.classList.add(classes.remainingOut);
      }

      if (remaining === 'in' || remaining >= this.remainingMaxInt) {
        this.remainingWrapper.classList.add(classes.remainingIn);
      }
      if (remaining === 'low' || (remaining > 0 && remaining < this.remainingMaxInt)) {
        this.remainingWrapper.classList.add(classes.remainingLow);

        if (this.remainingCount) {
          this.remainingCount.innerHTML = remaining;
        }
      }
    } else if (!variant && this.remainingWrapper) {
      this.remainingWrapper.classList.add(classes.remainingUnavailable);
    }
  }

  getBaseUnit(variant) {
    return variant.unit_price_measurement.reference_value === 1
      ? variant.unit_price_measurement.reference_unit
      : variant.unit_price_measurement.reference_value + variant.unit_price_measurement.reference_unit;
  }

  updateProductPrices(evt) {
    const variant = evt.dataset.variant;
    const priceWrappers = this.container.querySelectorAll(selectors.priceWrapper);

    priceWrappers.forEach((wrap) => {
      const comparePrice = wrap.querySelector(selectors.comparePrice);
      const productPrice = wrap.querySelector(selectors.productPrice);

      if (variant) {
        wrap.classList.remove(classes.hiddenPrice);
        productPrice.innerHTML = variant.price === 0 ? window.theme.translations.free : formatMoney(variant.price, theme.settings.moneyFormat);
      } else {
        wrap.classList.add(classes.hiddenPrice);
      }

      if (variant && variant.compare_at_price > variant.price) {
        comparePrice.innerHTML = formatMoney(variant.compare_at_price, theme.settings.moneyFormat);
        comparePrice.classList.remove(classes.visuallyHidden);
        productPrice.classList.add(classes.productPriceSale);
      } else if (comparePrice) {
        comparePrice.innerHTML = '';
        comparePrice.classList.add(classes.visuallyHidden);
        productPrice.classList.remove(classes.productPriceSale);
      }
    });

    if (this.hasUnitPricing) {
      this.updateProductUnits(evt);
    }
  }

  updateProductUnits(evt) {
    const variant = evt.dataset.variant;
    let unitPrice = null;

    if (variant && variant.unit_price) {
      unitPrice = variant.unit_price;
    }

    if (unitPrice) {
      const base = this.getBaseUnit(variant);
      const price = formatMoney(variant.unit_price, theme.moneyWithoutCurrencyFormat);

      this.container.querySelector(selectors.unitPrice).innerHTML = price;
      this.container.querySelector(selectors.unitBase).innerHTML = base;
      this.container.querySelector(selectors.unitWrapper).classList.remove(classes.hidden);
    } else {
      this.container.querySelector(selectors.unitWrapper).classList.add(classes.hidden);
    }
  }

  updateColorName(evt) {
    const target = evt.target;
    const optionLabel = target.closest(selectors.dataOption).querySelector(selectors.colorLabel);

    if (target.tagName === 'INPUT' && optionLabel !== null) {
      optionLabel.innerText = target.value;
    }
  }

  updateProductImage(evt) {
    const variant = evt.dataset.variant;

    if (variant) {
      // Update variant image, if one is set
      if (variant.featured_media) {
        const newImg = this.container.querySelector(`${selectors.productImage}[${selectors.dataImageId}*="${variant.featured_media.id}"]`);

        if (newImg) {
          const slider = this.container.querySelector(selectors.productSlideshow);

          if (slider && slider.classList.contains(classes.sliderEnabled)) {
            const newImagePos = Array.from(newImg.parentElement.children).indexOf(newImg);
            const flkty = FlickityAsNavFor.data(slider).select(newImagePos);
          }

          // Scroll to variant image
          if (this.scrollable) {
            if (window.innerWidth >= theme.sizes.small) {
              const headerHeight = 60; // Header height is always 60px on scroll
              window.scrollTo({
                top: newImg.getBoundingClientRect().top + window.scrollY - headerHeight,
                left: 0,
                behavior: 'smooth',
              });
            } else {
              const scroller = this.container.querySelector(selectors.productImagesScroller);
              scroller.scrollTo({
                top: 0,
                left: newImg.getBoundingClientRect().left + scroller.scrollLeft,
                behavior: 'smooth',
              });
            }
          }
        }
      }
    }
  }

  updateShopBarImage(evt) {
    const variant = evt.dataset.variant;
    const shopBarImages = this.container.querySelectorAll(selectors.variantId);
    const activeImage = this.container.querySelector(selectors.shopBarImageActive);

    if (!variant || variant.featured_image === null) {
      return;
    }

    if (activeImage) {
      activeImage.classList.remove(classes.shopBarImageShown);
      activeImage.removeAttribute(attributes.shopBarImageActive);
    }

    shopBarImages.forEach((element) => {
      if (Number(variant.id) === Number(element.getAttribute(attributes.variantId))) {
        element.classList.add(classes.shopBarImageShown);
        element.setAttribute(attributes.shopBarImageActive, '');
      }
    });
  }

  fireHookEvent(variant) {
    publish(events.variantChange, {
      data: {
        sectionId: this.sectionId,
        variant,
      },
    });
  }
}

const productFormSection = {
  onLoad() {
    this.section = new ProductAddForm(this);
  },
};

export {ProductAddForm, productFormSection};
