import FlickityFade from 'flickity-fade';
import parallaxHero from '../features/parallax-hero';
import {register} from '../vendor/theme-scripts/theme-sections';

const sections = {};

const selectors = {
  slider: '[data-gallery-slider]',
  slideshow: '[data-slider]',
  slideshowPrev: '[data-prev-arrow]',
  slideshowNext: '[data-next-arrow]',
  dataOptions: 'data-options',
  dataSlide: 'data-slide',
  dataSlideIndex: 'data-slide-index',
};

const classes = {
  slideshowLoading: 'gallery-slider--is-loading',
  classIsSelected: 'is-selected',
  flickityEnabled: 'flickity-enabled',
};

class Gallery {
  constructor(section) {
    this.container = section.container;
    this.slider = this.container.querySelector(selectors.slider);
    this.slideshow = this.container.querySelector(selectors.slideshow);
    this.options = this.slideshow.getAttribute(selectors.dataOptions);
    this.slideshowPrev = this.container.querySelector(selectors.slideshowPrev);
    this.slideshowNext = this.container.querySelector(selectors.slideshowNext);
    this.checkSliderVisibilityOnScrollEvent = this.checkSliderVisibility();
    this.flkty = null;

    this.init();
  }

  init() {
    let options = JSON.parse(this.options.replace(/'/g, '"'));

    options = {
      ...options,
      on: {
        ready: () => this.slider.classList.remove(classes.slideshowLoading),
      },
    };

    this.flkty = new FlickityFade(this.slideshow, options);

    if (this.slideshowPrev) {
      this.slideshowPrev.addEventListener('click', () => this.flkty.previous(true));
    }

    if (this.slideshowNext) {
      this.slideshowNext.addEventListener('click', () => this.flkty.next(true));
    }

    document.addEventListener('theme:scroll', this.checkSliderVisibilityOnScrollEvent);
  }

  isAutoplay() {
    const autoplay = this.flkty.options.autoPlay !== false;

    return autoplay;
  }

  checkSliderVisibility() {
    if (!this.flkty) return;

    const isInitialized = this.slideshow.classList.contains(classes.flickityEnabled);
    const isVisible = visibilityHelper.isElementPartiallyVisible(this.slideshow) || visibilityHelper.isElementTotallyVisible(this.slideshow);

    if (isVisible && isInitialized && this.isAutoplay()) {
      this.flkty.playPlayer();
    } else {
      this.flkty.stopPlayer();
    }
  }

  onUnload() {
    this.flkty.destroy();

    document.removeEventListener('theme:scroll', this.checkSliderVisibilityOnScrollEvent);
  }

  onBlockSelect(evt) {
    const slide = this.slideshow.querySelector(`[${selectors.dataSlide}="${evt.detail.blockId}"]`);
    const slideIndex = parseInt(slide.getAttribute(selectors.dataSlideIndex));

    // Go to selected slide, pause autoplay
    this.flkty.select(slideIndex);
    this.flkty.stopPlayer();
  }

  onBlockDeselect() {
    if (this.isAutoplay()) {
      this.flkty.playPlayer();
    }
  }
}

const GallerySection = {
  onLoad() {
    sections[this.id] = new Gallery(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
  onBlockSelect(e) {
    sections[this.id].onBlockSelect(e);
  },
  onBlockDeselect(e) {
    sections[this.id].onBlockDeselect(e);
  },
};

register('gallery', [GallerySection, parallaxHero]);
