import {load} from '../vendor/theme-scripts/theme-sections';
import loadScript from '../util/loader';
import wrap from '../util/wrap';

document.addEventListener('DOMContentLoaded', function () {
  // Load all registered sections on the page.
  load('*');

  // Smooth scroll to anchored element
  const anchorLinks = document.querySelectorAll('[data-anchor-link]');
  anchorLinks.forEach((anchorLink) => {
    anchorLink.addEventListener('click', (e) => {
      e.preventDefault();
      const targetElement = document.getElementById(anchorLink.getAttribute('href').split('#')[1]);
      const position = targetElement.getBoundingClientRect().top + window.scrollY - theme.dimensions.headerScrolled;

      window.scrollTo({
        top: position,
        left: 0,
        behavior: 'smooth',
      });
    });
  });

  // Target tables to make them scrollable
  const tableSelectors = '.rte table';
  const tables = document.querySelectorAll(tableSelectors);
  tables.forEach((table) => {
    wrap(table, 'rte__table-wrapper');
  });

  // Target iframes to make them responsive
  const iframeSelectors = '.rte iframe[src*="youtube.com/embed"], .rte iframe[src*="player.vimeo"], .rte iframe#admin_bar_iframe';
  const frames = document.querySelectorAll(iframeSelectors);
  frames.forEach((frame) => {
    wrap(frame, 'rte__video-wrapper');
  });

  if (window.self !== window.top) {
    document.querySelector('html').classList.add('iframe');
  }

  // Safari smoothscroll polyfill
  let hasNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  if (!hasNativeSmoothScroll) {
    loadScript({url: window.theme.assets.smoothscroll});
  }
});

// Apply a specific class to the html element for browser support of cookies.
if (window.navigator.cookieEnabled) {
  document.documentElement.className = document.documentElement.className.replace('supports-no-cookies', 'supports-cookies');
}
