import debounce from '../util/debounce';
import * as a11y from '../vendor/theme-scripts/theme-a11y';

const selectors = {
  menuToggle: '[data-menu-toggle]',
  hamburgerIcon: '[data-hamburger-icon]',
  hamburgerMenuScrollable: '[data-hamburger-scrollable]',
  headerIcons: '[data-header-icons]',
  navMain: '[data-nav-main]',
  menuDropdownParent: '[data-dropdown-parent]',
  dropdownTrigger: 'data-collapsible-trigger',
  menuItemLink: 'data-menu-item-link',
  visibleLink: 'data-visible-link',
  ariaExpanded: 'aria-expanded',
  href: 'href',
  tabIndex: 'tabindex',
};

const classes = {
  navVisible: 'nav--is-visible',
  navHiding: 'nav--is-hiding',
  megamenuVisible: 'header--megamenu-visible',
  headerHamburger: 'header--is-hamburger',
  menuItemDropdown: 'menu-item--dropdown',
  isExpanded: 'is-expanded',
  isActive: 'is-active',
  open: 'open',
};

let sections = {};

class Navigation {
  constructor(el) {
    this.header = el;
    this.body = document.body;
    this.menuToggles = document.querySelectorAll(selectors.menuToggle);
    this.headerIcons = this.header.querySelector(selectors.headerIcons);
    this.navStandard = this.header.querySelector(selectors.navMain);
    this.hamburger = this.header.querySelector(selectors.hamburgerIcon);
    this.scrollableElement = document.querySelector(selectors.hamburgerMenuScrollable);
    this.hamburgerNavLinks = this.scrollableElement.parentNode.querySelectorAll('button, a');
    this.documentClick = (e) => this.hideOnOutsideClick(e);
    this.documentKeyup = (e) => this.hideOnKeyUp(e);
    this.scrollLockTimeout = 0;
    this.resetHeight = 0;
    this.accessibility = a11y;

    this.init();
  }

  init() {
    this.hide();
    this.bindings();
    this.activeLinks();
  }

  bindings() {
    const dropdownParents = this.navStandard.querySelectorAll(selectors.menuDropdownParent);
    const emptyLinks = document.querySelectorAll(`${selectors.navMain} a[href^="#"]`);
    const dropdownTriggers = document.querySelectorAll(`${selectors.navMain} [${selectors.dropdownTrigger}]`);
    const triggers = [...dropdownTriggers, ...emptyLinks];

    // Init Bindings
    this.menuToggles.forEach((menuToggle) => {
      menuToggle.addEventListener('click', (e) => {
        e.preventDefault();

        if (this.body.classList.contains(classes.navVisible)) {
          this.hide();
        } else {
          this.show();
        }
      });
    });

    dropdownParents.forEach((dropdownParent) => {
      const visibleNavLinks = dropdownParent.querySelectorAll(`[${selectors.visibleLink}]`);

      dropdownParent.addEventListener('mouseenter', () => {
        if (theme.touched) {
          return;
        }

        if (!dropdownParent.classList.contains(classes.menuItemDropdown)) {
          this.header.classList.add(classes.megamenuVisible);
        }

        dropdownParent.classList.add(classes.isExpanded);
        visibleNavLinks.forEach((link) => {
          link.removeAttribute(selectors.tabIndex);

          if (link.hasAttribute(selectors.ariaExpanded)) {
            link.setAttribute(selectors.ariaExpanded, true);
          }
        });
      });

      dropdownParent.addEventListener('mouseleave', () => {
        if (!dropdownParent.classList.contains(classes.menuItemDropdown)) {
          this.header.classList.remove(classes.megamenuVisible);
        }

        dropdownParent.classList.remove(classes.isExpanded);
        visibleNavLinks.forEach((link) => {
          link.setAttribute(selectors.tabIndex, '-1');

          if (link.hasAttribute(selectors.ariaExpanded)) {
            link.setAttribute(selectors.ariaExpanded, false);
          }
        });
      });
    });

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        if (trigger.parentNode.classList.contains(classes.isExpanded)) {
          this.submenuClose(trigger.parentNode);
        } else {
          this.submenuOpen(trigger.parentNode);
        }
      });

      trigger.addEventListener('keyup', (e) => {
        if (e.keyCode === theme.keyboardKeys.SPACE) {
          if (trigger.parentNode.classList.contains(classes.isExpanded)) {
            this.submenuClose(trigger.parentNode);
          } else {
            this.submenuOpen(trigger.parentNode);
          }
        } else if (e.keyCode === theme.keyboardKeys.ESCAPE) {
          this.submenuClose(trigger.parentNode);
        }
      });
    });

    // Hide hamburger menu on click outside
    document.addEventListener('click', this.documentClick);

    // Close header dropdowns on focus another elements or ESC key is pressed
    document.addEventListener('keyup', this.documentKeyup);
  }

  hideOnOutsideClick(e) {
    const menuToggle = this.headerIcons.querySelector(selectors.menuToggle);
    const isMenuToggle = menuToggle.contains(e.target);
    const isMenuContainer = document.querySelector(selectors.hamburgerMenuScrollable).parentNode.contains(e.target);
    const isHeaderHamburger = this.header.classList.contains(classes.headerHamburger);

    // Close hamburger menu
    if (!isMenuToggle && !isMenuContainer && isHeaderHamburger) {
      this.hide();
    }
  }

  hideOnKeyUp(e) {
    const key = e.which || e.keyCode;
    const focusedElement = e.target;
    const hamburgerMenu = document.querySelectorAll(selectors.navMain)[1];
    const isHamburgerOpen = this.hamburger.classList.contains(classes.open);
    const expandedItem = document.querySelector(`${selectors.menuDropdownParent}.${classes.isExpanded}`);

    if (key !== theme.keyboardKeys.TAB && key !== theme.keyboardKeys.ESCAPE) {
      return;
    }

    if (key === theme.keyboardKeys.TAB) {
      // Close dropdown
      if (focusedElement.hasAttribute(selectors.menuItemLink) && expandedItem !== null) {
        expandedItem.querySelector(`[${selectors.dropdownTrigger}]`).dispatchEvent(new Event('click'));
      }

      // Close hamburger menu
      if (!hamburgerMenu.contains(focusedElement) && isHamburgerOpen) {
        this.hide();
      }
    } else if (key === theme.keyboardKeys.ESCAPE) {
      if (expandedItem !== null) {
        expandedItem.querySelector(`[${selectors.dropdownTrigger}]`).dispatchEvent(new Event('click'));
      }

      if (isHamburgerOpen) {
        this.hide();
      }
    }
  }

  show() {
    const headerIconsLinks = this.headerIcons.querySelectorAll(`a:not(${selectors.menuToggle})`);

    // Scroll lock
    document.dispatchEvent(new CustomEvent('theme:scroll:lock', {bubbles: true, detail: this.scrollableElement}));

    this.hamburger.classList.add(classes.open);
    this.body.classList.add(classes.navVisible);

    headerIconsLinks.forEach((iconLink) => iconLink.setAttribute(selectors.tabIndex, '-1'));
    this.hamburgerNavLinks.forEach((hamburgerNavLink) => hamburgerNavLink.removeAttribute(selectors.tabIndex));
    this.menuToggles.forEach((menuToggle) => menuToggle.setAttribute(selectors.ariaExpanded, true));
  }

  hide() {
    const headerIconsLinks = this.headerIcons.querySelectorAll('a');

    if (!this.body.classList.contains(classes.navVisible)) {
      return;
    }

    this.hamburger.classList.remove(classes.open);
    this.body.classList.add(classes.navHiding);
    this.body.classList.remove(classes.navVisible);

    if (this.scrollLockTimeout) {
      clearTimeout(this.scrollLockTimeout);
    }

    this.scrollLockTimeout = setTimeout(() => {
      // Scroll unlock
      document.dispatchEvent(new CustomEvent('theme:scroll:unlock', {bubbles: true}));
      this.body.classList.remove(classes.navHiding);
    }, 500);

    headerIconsLinks.forEach((iconLink) => iconLink.removeAttribute(selectors.tabIndex));
    this.hamburgerNavLinks.forEach((hamburgerNavLink) => hamburgerNavLink.setAttribute(selectors.tabIndex, '-1'));
    this.menuToggles.forEach((menuToggle) => menuToggle.setAttribute(selectors.ariaExpanded, false));
  }

  activeLinks() {
    const menuItemLinks = this.navStandard.querySelectorAll(`[${selectors.menuItemLink}]`);
    const visibleNavLinks = this.navStandard.querySelectorAll(`[${selectors.visibleLink}]`);
    let isTopLevel = false;

    menuItemLinks.forEach((link) => {
      if (link.getAttribute(selectors.href) === window.location.pathname) {
        link.parentNode.classList.add(classes.isActive);
        isTopLevel = true;
      }
    });

    if (!isTopLevel) {
      visibleNavLinks.forEach((link) => {
        if (link.getAttribute(selectors.href) === window.location.pathname) {
          link.parentNode.classList.add(classes.isActive);
          link.closest(selectors.menuDropdownParent).classList.add(classes.isActive);
        }
      });
    }
  }

  submenuClose(el) {
    const visibleNavLinks = el.querySelectorAll(`[${selectors.visibleLink}]`);

    visibleNavLinks.forEach((link) => {
      link.setAttribute(selectors.tabIndex, '-1');

      if (link.hasAttribute(selectors.ariaExpanded)) {
        link.setAttribute(selectors.ariaExpanded, false);
      }
    });

    if (!el.classList.contains(classes.menuItemDropdown)) {
      this.header.classList.remove(classes.megamenuVisible);
    }
  }

  submenuOpen(el) {
    const visibleNavLinks = el.querySelectorAll(`[${selectors.visibleLink}]`);

    visibleNavLinks.forEach((link) => {
      link.removeAttribute(selectors.tabIndex);

      if (link.hasAttribute(selectors.ariaExpanded)) {
        link.setAttribute(selectors.ariaExpanded, true);
      }
    });

    if (!el.classList.contains(classes.menuItemDropdown)) {
      this.header.classList.add(classes.megamenuVisible);
    }
  }

  unload() {
    document.removeEventListener('click', this.documentClick);
    document.removeEventListener('keyup', this.documentKeyup);
  }
}

const navigation = {
  onLoad() {
    sections = new Navigation(this.container);
  },
  onUnload: function () {
    if (typeof sections.unload === 'function') {
      sections.unload();
    }
  },
  onSelect() {
    if (typeof sections.hide === 'function') {
      sections.hide();
    }
  },
  onDeselect() {
    if (typeof sections.hide === 'function') {
      sections.hide();
    }
  },
};

export default navigation;
