import FlickityFade from 'flickity-fade';
import parallaxHero from '../features/parallax-hero';
import {register} from '../vendor/theme-scripts/theme-sections';

const sections = {};

const selectors = {
  lazyImage: '[loading="lazy"]',
  shopifySection: '.shopify-section',
  slideshowPrev: '[data-prev-arrow]',
  slideshowNext: '[data-next-arrow]',
  header: '[data-header]',
  dataOptions: 'data-options',
  dataColor: 'data-style',
  dataCurrentColor: 'data-current-style',
  dataSlide: 'data-slide',
  dataSlideIndex: 'data-slide-index',
  scrollBtn: '[data-button-scroll]',
};

const classes = {
  slideshowLoading: 'hero--is-loading',
  classIsSelected: 'is-selected',
  flickityEnabled: 'flickity-enabled',
};

class Slideshow {
  constructor(section) {
    this.container = section.container;
    this.options = this.container.getAttribute(selectors.dataOptions);
    this.parentContainer = this.container.closest(selectors.shopifySection);
    this.slideshowPrev = this.parentContainer.querySelector(selectors.slideshowPrev);
    this.slideshowNext = this.parentContainer.querySelector(selectors.slideshowNext);
    this.scrollBtn = this.parentContainer.querySelector(selectors.scrollBtn);
    this.checkVisibilityOnScroll = this.checkSliderVisibility();
    this.flkty = null;

    this.init();
  }

  init() {
    let options = JSON.parse(this.options.replace(/'/g, '"'));

    options = {
      ...options,
      cellSelector: `[${selectors.dataSlide}]`,
      on: {
        ready: () => {
          requestAnimationFrame(() => {
            this.container.classList.remove(classes.slideshowLoading);
          });
          this.slideActions();
        },
      },
    };

    this.flkty = new FlickityFade(this.container, options);
    this.flkty.on('change', () => {
      this.slideActions();
    });

    if (this.slideshowPrev) {
      this.slideshowPrev.addEventListener('click', () => this.flkty.previous(true));
    }

    if (this.slideshowNext) {
      this.slideshowNext.addEventListener('click', () => this.flkty.next(true));
    }

    if (this.scrollBtn) {
      this.scrollBtn.addEventListener('click', (e) => {
        e.preventDefault();

        const headerHeight = 59;
        const containerTop = this.parentContainer.offsetTop;
        const containerHeight = this.parentContainer.offsetHeight;
        const scrollPosition = containerTop + containerHeight - headerHeight;

        scroll({
          top: scrollPosition,
          behavior: 'smooth',
        });
      });
    }

    document.addEventListener('theme:scroll', this.checkVisibilityOnScroll);
  }

  isAutoplay() {
    const autoplay = this.flkty.options.autoPlay !== false;

    return autoplay;
  }

  checkSliderVisibility() {
    if (!this.flkty) {
      return;
    }

    const isInitialized = this.container.classList.contains(classes.flickityEnabled);
    const isVisible = window.visibilityHelper.isElementPartiallyVisible(this.container) || window.visibilityHelper.isElementTotallyVisible(this.container);

    if (isVisible && isInitialized && this.isAutoplay()) {
      this.flkty.playPlayer();
    } else {
      this.flkty.stopPlayer();
    }
  }

  slideActions() {
    const currentSlide = this.container.querySelector(`.${classes.classIsSelected}`);
    const currentSlideColor = currentSlide.getAttribute(selectors.dataColor);

    this.container.setAttribute(selectors.dataCurrentColor, currentSlideColor);
  }

  onUnload() {
    this.flkty.destroy();

    document.removeEventListener('theme:scroll', this.checkVisibilityOnScroll);
  }

  onBlockSelect(evt) {
    const slide = this.container.querySelector(`[${selectors.dataSlide}="${evt.detail.blockId}"]`);
    const slideIndex = parseInt(slide.getAttribute(selectors.dataSlideIndex));

    // Go to selected slide, pause autoplay
    this.flkty.select(slideIndex);
    this.flkty.stopPlayer();
  }

  onBlockDeselect() {
    if (this.isAutoplay()) {
      this.flkty.playPlayer();
    }
  }
}

const SlideshowSection = {
  onLoad() {
    sections[this.id] = new Slideshow(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
  onBlockSelect(e) {
    sections[this.id].onBlockSelect(e);
  },
  onBlockDeselect(e) {
    sections[this.id].onBlockDeselect(e);
  },
};

register('slideshow', [SlideshowSection, parallaxHero]);
