import floatLabels from '../globals/forms';
import {setVarsOnResize, setVars} from '../globals/height';
import preventOverflow from '../globals/prevent-overflow';
import resizeListener from '../globals/resize';
import scrollListener from '../globals/scroll';
import isTouch from '../util/touch';
import {ariaToggle} from '../globals/legacy';
import {loadedImagesEventHook, removeLoadingClassFromLoadedImages} from '../globals/images';
import preloadImages from '../globals/preload-images';
import PredictiveSearch from '../globals/predictive-search';
import {initAnimations} from '../globals/animations';

// Safari requestIdleCallback polyfill
window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    var start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };
window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };

// Animate on scroll
const showAnimations = document.body.getAttribute('data-animations') === 'true';
if (showAnimations) {
  initAnimations();
}

resizeListener();
scrollListener();
isTouch();
setVars();
loadedImagesEventHook();

window.addEventListener('DOMContentLoaded', () => {
  setVarsOnResize();
  floatLabels(document);
  preventOverflow(document);
  ariaToggle(document);
  removeLoadingClassFromLoadedImages(document);

  if (window.fastNetworkAndCPU) {
    preloadImages();
  }
});

document.addEventListener('shopify:section:load', (e) => {
  const container = e.target;
  floatLabels(container);
  preventOverflow(container);
  ariaToggle(document);

  document.dispatchEvent(new CustomEvent('theme:header:update', {bubbles: true}));
});

document.addEventListener('shopify:section:reorder', () => {
  document.dispatchEvent(new CustomEvent('theme:header:update', {bubbles: true}));
});

document.addEventListener('shopify:section:unload', () => {
  // When you hide/disable a section, the unload event is fired before the section is actually removed from the DOM
  // We need a little delay before checking for transparent header to make sure it's already removed from the DOM
  setTimeout(() => {
    document.dispatchEvent(new CustomEvent('theme:header:update', {bubbles: true}));
  }, 200);
});
