function ariaToggle(container) {
  const toggleButtons = container.querySelectorAll('[data-aria-toggle]');
  if (toggleButtons.length) {
    toggleButtons.forEach((element) => {
      element.addEventListener('click', function (event) {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        currentTarget.setAttribute('aria-expanded', currentTarget.getAttribute('aria-expanded') == 'false' ? 'true' : 'false');
        const toggleID = currentTarget.getAttribute('aria-controls');
        document.querySelector(`#${toggleID}`).classList.toggle('expanding');
        setTimeout(function () {
          document.querySelector(`#${toggleID}`).classList.toggle('expanded');
        }, 40);
      });
    });
  }
}

export {ariaToggle};
