const outerWidth = (el) => {
  const style = getComputedStyle(el);
  let width = el.offsetWidth;

  width += parseInt(style.marginLeft) + parseInt(style.marginRight);

  return width;
};

export default outerWidth;
