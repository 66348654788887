import Flickity from 'flickity';
import FlickityFade from 'flickity-fade';

const selectors = {
  slider: '[data-slider]',
  slide: '[data-slide]',
  slideValue: 'data-slide',
  dataAutoplay: 'data-autoplay',
  dataAutoplaySpeed: 'data-speed',
  dataWatchCss: 'data-watch-css',
  dataDraggable: 'data-draggable',
  dataSlideIndex: 'data-slide-index',
  dataSliderStartIndex: 'data-slider-start-index',
  dataArrowPositionMiddle: 'data-arrow-position-middle',
  dataFade: 'data-fade',
};

const classes = {
  classIsSelected: 'is-selected',
  classSliderInitialized: 'js-slider--initialized',
  classSliderArrowsHidden: 'flickity-button-hide',
};

const sections = {};

class Slider {
  constructor(container, slideshow = null, slideSelector = '') {
    this.container = container;
    this.slideshow = slideshow || this.container.querySelector(selectors.slider);

    if (!this.slideshow) {
      return;
    }

    this.slideshowSlides = this.slideshow.querySelectorAll(selectors.slide);
    this.autoPlay = this.slideshow.getAttribute(selectors.dataAutoplay) === 'true';
    this.autoPlaySpeed = this.slideshow.getAttribute(selectors.dataAutoplaySpeed);
    this.infinite = this.slideshow.getAttribute(selectors.dataInfinite) !== 'false';
    this.watchCss = this.slideshow.getAttribute(selectors.dataWatchCss) === 'true';
    this.draggable = this.slideshow.getAttribute(selectors.dataDraggable) !== 'false';
    this.sliderStartIndex = this.slideshow.hasAttribute(selectors.dataSliderStartIndex);
    this.fade = this.slideshow.getAttribute(selectors.dataFade) === 'true';
    this.slideSelector = slideSelector;

    this.flkty = null;
    this.init();
  }

  init() {
    const sliderOptions = {
      initialIndex: this.sliderStartIndex ? parseInt(this.slideshow.getAttribute(selectors.dataSliderStartIndex)) : 0,
      autoPlay: this.autoPlay && this.autoPlaySpeed ? parseInt(this.autoPlaySpeed) : false,
      contain: true,
      pageDots: false,
      wrapAround: this.infinite,
      percentPosition: this.percentPosition,
      watchCSS: this.watchCss,
      draggable: this.draggable ? '>1' : false,
      cellSelector: this.slideSelector,
      on: {
        ready: () => {
          requestAnimationFrame(() => {
            this.slideshow.parentNode.dispatchEvent(
              new CustomEvent('theme:slider:loaded', {
                bubbles: true,
                detail: {
                  slider: this,
                },
              })
            );
          });

          if (this.slideshow.classList.contains(classes.classIsSelected)) {
            this.slideshow.classList.remove(classes.classIsSelected);
          }
        },
      },
    };

    if (this.fade) {
      sliderOptions.fade = true;
      this.flkty = new FlickityFade(this.slideshow, sliderOptions);
    }

    if (!this.fade) {
      this.flkty = new Flickity(this.slideshow, sliderOptions);
    }
  }

  onUnload() {
    if (this.slideshow && this.flkty) {
      this.flkty.options.watchCSS = false;
      this.flkty.destroy();
    }
  }

  onBlockSelect(evt) {
    if (!this.slideshow) {
      return;
    }
    // Ignore the cloned version
    const slide = this.slideshow.querySelector(`[${selectors.slideValue}="${evt.detail.blockId}"]`);

    if (!slide) {
      return;
    }
    let slideIndex = parseInt(slide.getAttribute(selectors.dataSlideIndex));

    if (this.multipleSlides && !this.slideshow.classList.contains(classes.classSliderInitialized)) {
      slideIndex = 0;
    }

    this.slideshow.classList.add(classes.classIsSelected);

    // Go to selected slide, pause autoplay
    this.flkty.selectCell(slideIndex);
    this.flkty.stopPlayer();
  }

  onBlockDeselect() {
    if (!this.slideshow) {
      return;
    }
    this.slideshow.classList.remove(classes.classIsSelected);

    if (!this.autoPlay) {
      return;
    }
    this.flkty.playPlayer();
  }
}

const slider = {
  onLoad() {
    sections[this.id] = [];
    const els = this.container.querySelectorAll(selectors.slider);
    els.forEach((el) => {
      sections[this.id].push(new Slider(this.container, el));
    });
  },
  onUnload() {
    sections[this.id].forEach((el) => {
      if (typeof el.onUnload === 'function') {
        el.onUnload();
      }
    });
  },
  onBlockSelect(e) {
    sections[this.id].forEach((el) => {
      if (typeof el.onBlockSelect === 'function') {
        el.onBlockSelect(e);
      }
    });
  },
  onBlockDeselect(e) {
    sections[this.id].forEach((el) => {
      if (typeof el.onBlockDeselect === 'function') {
        el.onBlockDeselect(e);
      }
    });
  },
};

export {slider, Slider};
