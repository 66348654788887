const selectors = {
  accordion: '[data-accordion]',
  trigger: '[data-accordion-trigger]',
  body: '[data-accordion-body]',
  content: '[data-accordion-content]',
  focusable: 'input, button, a',
};

const attributes = {
  open: 'open',
  single: 'single',
};

class AccordionElements extends HTMLElement {
  constructor() {
    super();

    this.accordions = this.querySelectorAll(selectors.accordion);
  }

  connectedCallback() {
    this.accordions.forEach((accordion) => {
      const trigger = accordion.querySelector(selectors.trigger);
      const body = accordion.querySelector(selectors.body);

      trigger.addEventListener('click', (event) => this.onCollapsibleClick(event));

      body.addEventListener('transitionend', (event) => {
        if (event.target !== body) return;

        if (accordion.getAttribute(attributes.open) == 'true') {
          this.setBodyHeight(body, 'auto');

          // Focus on the first focusable element in the details tag
          accordion.querySelector(selectors.focusable)?.focus();
        }

        if (accordion.getAttribute(attributes.open) == 'false') {
          accordion.removeAttribute(attributes.open);
          this.setBodyHeight(body, '');
        }
      });
    });

    this.addEventListener('keyup', (event) => this.onKeyUp(event));
  }

  open(accordion) {
    if (accordion.getAttribute('open') == 'true') return;

    const body = accordion.querySelector(selectors.body);
    const content = accordion.querySelector(selectors.content);

    accordion.setAttribute('open', true);

    this.setBodyHeight(body, content.offsetHeight);
  }

  close(accordion) {
    if (!accordion.hasAttribute('open')) return;

    const body = accordion.querySelector(selectors.body);
    const content = accordion.querySelector(selectors.content);

    this.setBodyHeight(body, content.offsetHeight);

    accordion.setAttribute('open', false);

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setBodyHeight(body, 0);
      });
    });
  }

  setBodyHeight(body, contentHeight) {
    body.style.height = contentHeight !== 'auto' && contentHeight !== '' ? `${contentHeight}px` : contentHeight;
  }

  onCollapsibleClick(event) {
    event.preventDefault();

    const accordion = event.target.closest(selectors.accordion);
    const single = this.hasAttribute(attributes.single);

    // When we want only one item expanded at the same time
    if (single) {
      this.accordions.forEach((otherCollapsible) => {
        // if otherCollapsible has attribute open and it's not the one we clicked on, remove the open attribute
        if (otherCollapsible.hasAttribute(attributes.open) && otherCollapsible != accordion) {
          requestAnimationFrame(() => {
            this.close(otherCollapsible);
          });
        }
      });
    }

    if (accordion.hasAttribute(attributes.open)) {
      this.close(accordion);
    } else {
      this.open(accordion);
    }
  }

  onKeyUp(event) {
    const accordion = this.querySelector('details[open]');

    if (event.code == 'Escape' && accordion) {
      accordion.querySelector('summary').dispatchEvent(new Event('click'));
    }
  }
}

if (!customElements.get('accordion-elements')) {
  customElements.define('accordion-elements', AccordionElements);
}

export default AccordionElements;
