function readHeights() {
  const h = {};
  h.windowHeight = window.innerHeight;
  h.footerHeight = getHeight('[data-section-type*="footer"]');
  h.headerHeight = getHeight('[data-header-height].header--has-scrolled') || 60; // Header height is always 60px on scroll
  h.headerInitialHeight = getHeight('[data-header-height]:not(.header--has-scrolled)');
  return h;
}

function setVarsOnResize() {
  document.addEventListener('theme:resize', resizeVars);
}

function setVars() {
  const {windowHeight, headerInitialHeight, headerHeight, footerHeight} = readHeights();

  document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
  document.documentElement.style.setProperty('--header-initial-height', `${headerInitialHeight}px`);

  document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
  document.documentElement.style.setProperty('--content-full', `${windowHeight - headerHeight}px`);
  document.documentElement.style.setProperty('--content-min', `${windowHeight - headerHeight - footerHeight}px`);
  document.documentElement.style.setProperty('--scrollbar-width', `${getScrollbarWidth()}px`);
}

function resizeVars() {
  // restrict the heights that are changed on resize to avoid iOS jump when URL bar is shown and hidden
  const {windowHeight, headerHeight, footerHeight} = readHeights();
  document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

  document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
  document.documentElement.style.setProperty('--content-full', `${windowHeight - headerHeight}px`);
  document.documentElement.style.setProperty('--content-min', `${windowHeight - headerHeight - footerHeight}px`);
}

function getHeight(selector) {
  const el = document.querySelector(selector);
  if (el) {
    return el.clientHeight;
  } else {
    return 0;
  }
}

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export {setVarsOnResize, readHeights, setVars, getHeight};
