import Flickity from 'flickity';
import CustomScrollbar from '../features/custom-scrollbar';

const selectors = {
  productContainer: '[data-product-container]',
  productSlideshow: '[data-product-slideshow]',
  productImage: '[data-product-single-media-wrapper]',
  productThumbs: '[data-product-single-media-thumbs]',
  productThumb: '[data-thumbnail]',
  productThumbLink: '[data-thumbnail-id]',
  deferredMediaButton: '[data-deferred-media-button]',
  mediaType: 'data-type',
  id: 'data-id',
  tabIndex: 'tabindex',
  arrows: 'data-arrows',
  dots: 'data-dots',
};

const classes = {
  active: 'active',
  sliderEnabled: 'flickity-enabled',
  scrollable: 'product--layout-scrollable',
  mediaHidden: 'media--hidden',
  focusEnabled: 'is-focused',
  thumbsArrows: 'product__images__slider-nav--arrows',
  isMoving: 'is-moving',
};

class InitSlider {
  constructor(section) {
    this.container = section.container;
    this.productContainer = this.container.querySelector(selectors.productContainer);
    this.scrollable = this.productContainer.classList.contains(classes.scrollable);
    this.slideshow = this.container.querySelector(selectors.productSlideshow);
    this.productImages = this.container.querySelectorAll(selectors.productImage);
    this.thumbs = this.container.querySelector(selectors.productThumbs);
    this.flkty = null;
    this.flktyNav = null;
    this.checkThumbsWidthResizeEvent = () => this.checkThumbsWidth();
    this.scrollableResizeEvent = () => this.toggleCustomScrollbar();

    if (this.productImages.length > 1) {
      this.init();
    }
  }

  init() {
    this.createSlider();
    this.createSliderNav();
    this.createScrollable();

    document.addEventListener('theme:resize', this.checkThumbsWidthResizeEvent);
  }

  createSlider() {
    if (!this.slideshow) {
      return;
    }

    const instance = this;
    const firstSlide = this.slideshow.querySelectorAll(`[${selectors.mediaType}]`)[0];
    const arrows = this.slideshow.getAttribute(selectors.arrows) === 'true';
    const dots = this.slideshow.getAttribute(selectors.dots) === 'true';

    const flickityOptions = {
      autoPlay: false,
      arrowShape: theme.icons.arrowNavSlider,
      prevNextButtons: arrows,
      contain: true,
      pageDots: dots,
      adaptiveHeight: true,
      wrapAround: true,
    };

    this.flkty = new Flickity(this.slideshow, flickityOptions);

    if (firstSlide) {
      const firstType = firstSlide.getAttribute(selectors.mediaType);

      if (firstType === 'model' || firstType === 'video' || firstType === 'external_video') {
        this.flkty.options.draggable = false;
        this.flkty.updateDraggable();
      }
    }

    this.flkty.on('dragStart', function () {
      instance.slideshow.classList.add(classes.isMoving);
    });

    this.flkty.on('change', function (index) {
      const currentMedia = this.cells[index].element;
      const newMedia = this.selectedElement;

      currentMedia.dispatchEvent(new CustomEvent('theme:media:hidden'));
      newMedia.classList.remove(classes.mediaHidden);
    });

    this.flkty.on('settle', function () {
      const currentMedia = this.selectedElement;
      const mediaType = currentMedia.getAttribute(selectors.mediaType);

      if (mediaType === 'model' || mediaType === 'video' || mediaType === 'external_video') {
        // first boolean sets value, second option false to prevent refresh
        instance.flkty.options.draggable = false;
        instance.flkty.updateDraggable();
      } else {
        instance.flkty.options.draggable = true;
        instance.flkty.updateDraggable();
      }

      instance.switchMedia(currentMedia);
      instance.slideshow.classList.remove(classes.isMoving);
    });
  }

  createSliderNav() {
    if (!this.thumbs || !this.slideshow) {
      return;
    }

    const flickityOptionsNav = {
      asNavFor: this.slideshow,
      pageDots: false,
      prevNextButtons: true,
      arrowShape: theme.icons.arrowNavSlider,
      groupCells: true,
      contain: true,
    };

    this.flktyNav = new Flickity(this.thumbs, flickityOptionsNav);

    this.checkThumbsWidth();

    this.thumbs.querySelectorAll(selectors.productThumbLink).forEach((thumbLink) => {
      thumbLink.addEventListener('click', (e) => {
        e.preventDefault();
      });
    });
  }

  checkThumbsWidth() {
    if (!this.thumbs) {
      return;
    }

    const thumbs = this.thumbs.querySelectorAll(selectors.productThumb);
    const thumbsContainerPadding = parseInt(window.getComputedStyle(this.thumbs).paddingLeft.replace('px', '')) * 2;
    const thumbsContainerWidth = this.thumbs.offsetWidth - thumbsContainerPadding;
    let thumbsWidth = 0;

    thumbs.forEach((thumb) => {
      thumbsWidth += thumb.offsetWidth;
    });

    if (thumbsContainerWidth < thumbsWidth) {
      this.thumbs.classList.add(classes.thumbsArrows);
    } else {
      this.thumbs.classList.remove(classes.thumbsArrows);
    }

    if (typeof this.flktyNav == 'object') {
      this.flktyNav.resize();
    }
  }

  createScrollable() {
    if (!this.scrollable) {
      return;
    }

    this.toggleCustomScrollbar();
    document.addEventListener('theme:resize', this.scrollableResizeEvent);
  }

  toggleCustomScrollbar() {
    if (window.innerWidth < theme.sizes.small) {
      this.customScrollbar = new CustomScrollbar(this.container);
    } else if (this.customScrollbar) {
      this.customScrollbar.destroy();
    }
  }

  switchMedia(currentMedia) {
    const otherMedia = Array.prototype.filter.call(currentMedia.parentNode.children, function (child) {
      return child !== currentMedia;
    });
    const isFocusEnabled = document.body.classList.contains(classes.focusEnabled);

    if (isFocusEnabled) {
      currentMedia.dispatchEvent(new Event('focus'));
    }

    if (otherMedia.length) {
      otherMedia.forEach((element) => {
        element.classList.add(classes.mediaHidden);
        element.dispatchEvent(new CustomEvent('theme:media:hidden'));
      });
    }

    currentMedia.classList.remove(classes.mediaHidden);
    currentMedia.dispatchEvent(new CustomEvent('theme:media:visible'));

    // Force media loading if slide becomes visible
    const deferredMedia = currentMedia.querySelector('deferred-media');
    if (deferredMedia && deferredMedia.getAttribute('loaded') !== true) {
      currentMedia.querySelector(selectors.deferredMediaButton).dispatchEvent(new Event('click', {bubbles: false}));
    }
  }

  unload() {
    document.removeEventListener('theme:resize', this.checkThumbsWidthResizeEvent);
  }
}

export default InitSlider;
