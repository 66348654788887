import {register} from '../vendor/theme-scripts/theme-sections';
import removeAnimations from '../features/remove-animations';
import {ajaxify} from '../features/infinity-scroll';

const sections = {};

class Blog {
  constructor(section) {
    this.container = section.container;
    this.checkWidthOnResize = this.checkWindowWidth();

    this.init();
  }

  init() {
    this.checkWindowWidth();

    document.addEventListener('theme:resize', this.checkWidthOnResize);
  }

  checkWindowWidth() {
    if (window.innerWidth < theme.sizes.small) {
      removeAnimations(this.container);
    }
  }

  onUnload() {
    document.removeEventListener('theme:resize', this.checkWidthOnResize);
  }
}

const BlogSection = {
  onLoad() {
    sections[this.id] = new Blog(this);
  },
  onUnload(e) {
    sections[this.id].onUnload(e);
  },
};

register('blog', [BlogSection, ajaxify]);
register('featured-blog', BlogSection);
